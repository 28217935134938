import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { getCurrentUserFromJwtToken, getWebsiteId, getWebsiteIsAnonymous, setWebsiteInfoSession, getWebsiteInfo, clearAllSessionData } from '../../js/Utils.js';
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import AppRoutes from '../../AppRoutes.js';
import AppRoutes_Document from '../../AppRoutes_Document.js';

export const AuthContext = React.createContext({});
export const useAuth = () => { return React.useContext(AuthContext); };

export const AuthProvider = ({ children }) => {
    const [defaultModule, setDefaultModule] = useState('');
    const [moduleSelected, setModuleSelected] = useState('');
    const [quotationInfo, setQuotationInfo] = useState({});
    const [addToOrder, setAddToOrder] = useState({});

    const cookies = new Cookies();
    const location = useLocation();

    const onCallLoginHowAnonymous = async () => {
        const response = await loginAnonymous();
        if (response.success === true && response.isanonymous) {
            handleLogin(response.token, null, null, response.isanonymous, null, response.startModule);
            //window.location.href = configData.Settings.Base_Url;
        }
    }

    useEffect(() => {
        let isCrossLogin = manageCrossLogin();

        if (isCrossLogin)
            return;

        onCallWebsiteInfo();

    }, []);

    useEffect(() => {
        let isHome = !location.pathname || location.pathname === "/";
        if (isHome || isProductPage(location.pathname)) {
            const jCurrentUser = localStorage.getItem("CurrentUser");
            if (jCurrentUser !== null && jCurrentUser !== undefined) {
                let currentUser = JSON.parse(jCurrentUser);

                setDefaultModule(currentUser.startModule);

                if (isHome)
                    setModuleSelected(currentUser.startModule);
                else if (!currentUser.selectModule)
                    setModuleSelected(currentUser.startModule);
                else
                    setModuleSelected(currentUser.selectModule);
            } else {
                let websiteInfo = getWebsiteInfo();
                if (websiteInfo && websiteInfo.startModule)
                    setModuleSelected(websiteInfo.startModule);

                //console.log(websiteInfo.startModule);
            }
        } else {
            let websiteInfo = getWebsiteInfo();
            if (websiteInfo && websiteInfo.startModule)
                setModuleSelected(websiteInfo.startModule);
            else
                setModuleSelected('NOMODULE');
        }

    }, [location]);

    const onCallWebsiteInfo = async () => {
        const response = await callWebsiteInfo();
        if (response && response.success === true && response.isanonymous) {
            handleLogin(response.token, null, null, response.isanonymous, null, response.startModule);
            //window.location.href = configData.Settings.Base_Url;
        }
        else if (!response || !response.success) {

            const jCurrentUser = localStorage.getItem("CurrentUser");
            if (jCurrentUser !== null && jCurrentUser !== undefined) {
                let currentUser = JSON.parse(jCurrentUser);
                setDefaultModule(currentUser.startModule);

                let today = new Date();
                let isExpireDate = !currentUser.expire;
                if (!isExpireDate) {
                    isExpireDate = new Date(currentUser.expire).getTime() < today.getTime();

                    try {

                        let normToday = new Date(new Date().toISOString().split('T')[0]).getTime();
                        let normExpireDate = new Date(currentUser.expire.split('T')[0]).getTime();
                        isExpireDate = normExpireDate < normToday;

                    } catch (ex) {

                    }
                }

                if (isExpireDate) {
                    handleLogout();
                } else {

                    const sessionToken = cookies.get("SessionToken");
                    if (!sessionToken && getWebsiteIsAnonymous())
                        onCallLoginHowAnonymous();

                    setDefaultModule(currentUser.startModule);
                }

            } else {
                if (getWebsiteIsAnonymous())
                    onCallLoginHowAnonymous();
            }

            const jQuotationInfo = localStorage.getItem("QuotationInfo");
            if (jQuotationInfo !== null && jQuotationInfo !== undefined) {
                let currentQuotationInfo = JSON.parse(jQuotationInfo);
                setQuotationInfo(currentQuotationInfo);
            }

            const jAddToOrder = localStorage.getItem("AddToOrder");
            if (jAddToOrder !== null && jAddToOrder !== undefined) {
                let currentAddToOrder = JSON.parse(jAddToOrder);
                setAddToOrder(currentAddToOrder);
            }

        }
    }

    async function callWebsiteInfo() {
        let data = {
            path: getPath()
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'website/getWebsiteInfo', requestOption);
        const response = await fetchedRes.json();
        if (response.success === true) {
            clearAllSessionData();
            setWebsiteInfoSession(response.websiteInfo);
        }

        return response;
    }

    function getPath() {
        const url = window.location.href;
        const parts = url.split('/');
        return parts[parts.length - 1];
    }

    function manageCrossLogin() {
        const loginWithParams = async (credentials, redirectUrl) => {
            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials)
            };

            let path = credentials.ssoToken !== null && credentials.ssoToken !== undefined
                ? configData.Settings.CommonApi_BaseUrl + 'auth/loginSSO'
                : configData.Settings.CommonApi_BaseUrl + 'auth/loginSTkn';

            try {
                const fetchedRes = await fetch(path, requestOption);
                const response = await fetchedRes.json();

                if (response.success === true) {
                    handleLogin(response.token, redirectUrl, response.impersonate, response.isanonymous, response.addToOrder, response.startModule);
                }

            } catch (ex) {
                console.error(ex);
            }
        }

        let params = new URLSearchParams(window.location.search);
        let mySSOToken = params.get('ssoToken');
        let mySTkn = params.get('sTkn');
        let redirectUrl = params.get('ru');
        let orderNumber = params.get('ato');
        let autoLoginCode = params.get('ual');
        let productType = params.get('productType');
        let crossLoginVersion = params.get('xl');

        if (autoLoginCode) {
            autoLogin(autoLoginCode, redirectUrl);
            return true;
        }

        if (!mySSOToken && !mySTkn)
            return false;
        else {
            cookies.remove("CultureName", { path: '/' });
            cookies.remove("SessionToken", { path: '/' });

            loginWithParams({
                username: null,
                password: null,
                ssoToken: mySSOToken,
                sessionToken: mySTkn,
                websiteId: getWebsiteId(),
                orderNumber: orderNumber,
                productType: productType,
                crossLoginVersion: crossLoginVersion
            }, redirectUrl).catch(console.error);
        }

        return true;
    }

    async function autoLogin(authCode, redirectUrl) {
        let orderInfo = { authCode: authCode, websiteId: getWebsiteId() }
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(orderInfo) };
        const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'auth/AutoLogin', requestOption).then(data => data.json());

        if (response.success) {
            handleLogin(response.token, redirectUrl, null, response.isanonymous, response.addToOrder, response.startModule);
        }
    }

    function isProductPage(pathName) {
        pathName = pathName.toLowerCase()

        if (pathName.includes('engine'))
            return true;

        if (pathName.includes('activity/') || pathName.includes('activityhome'))
            return true;

        if (pathName.includes('cruise/') || pathName.includes('cruisehome'))
            return true;

        if (pathName.includes('carrental/') || pathName.includes('carhome'))
            return true;

        if (pathName.includes('flight/') || pathName.includes('flighthome'))
            return true;

        if (pathName.includes('hotel/') || pathName.includes('hotelhome'))
            return true;

        if (pathName.includes('train/') || pathName.includes('trainhome'))
            return true;

        if (pathName.includes('transfer/') || pathName.includes('transferhome'))
            return true;

        if (pathName.includes('tour/') || pathName.includes('tourhome'))
            return true;

        if (pathName.includes('home'))
            return true;

        return false;
    }

    async function logoutUser() {
        return fetch(configData.Settings.CommonApi_BaseUrl + 'auth/logout/' + getWebsiteId(), { credentials: 'include' }).then(data => data.json());
    }

    async function loginAnonymous() {
        let credentials = {
            websiteId: getWebsiteId()
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials)
        };

        return fetch(configData.Settings.CommonApi_BaseUrl + 'auth/loginanonymous', requestOption).then(data => data.json());
    }

    const handleLogin = (newToken, redirectUrl, impersonate, isanonymous, addToOrder, startModule) => {
        let currentUser = getCurrentUserFromJwtToken(newToken);

        if (currentUser.name === null || currentUser.name === undefined || currentUser.name === "")
            currentUser.name = currentUser.username;

        if (impersonate !== null)
            currentUser.impersonate = impersonate;

        if (isanonymous !== null)
            currentUser.isanonymous = isanonymous;

        currentUser.startModule = startModule;
        setModuleSelected(startModule);
        setDefaultModule(startModule);

        let jCurrentUser = JSON.stringify(currentUser);
        localStorage.setItem("CurrentUser", jCurrentUser);

        if (addToOrder !== null) {
            setAddToOrderSession(addToOrder);
        }

        let fromPathName = location.state?.from?.pathname;
        if (fromPathName && location.state.from.search)
            fromPathName += location.state.from.search;

        const origin = redirectUrl !== null && redirectUrl !== undefined ? redirectUrl : fromPathName || configData.Settings.Base_Url;

        let redirect = true;
        try {
            let noRedirectPage = AppRoutes.filter(x => x.patternNoRedirect);
            let noRedirectPageDocument = AppRoutes_Document.filter(x => x.patternNoRedirect);

            noRedirectPage = noRedirectPage.concat(noRedirectPageDocument);

            for (let i = 0; i < noRedirectPage.length; i++) {
                let pattern = noRedirectPage[i].patternNoRedirect;
                if (pattern.test(window.location.pathname))
                    redirect = false;
            }
        } catch (ex) { }

        if (redirect)
            window.location.href = origin;
    };

    const handleLogout = () => {
        const onCallLogout = async () => {
            const response = await logoutUser();

            if (response.success === true) {
                localStorage.removeItem("CurrentUser");
                localStorage.removeItem("QuotationInfo");
                localStorage.removeItem("WelfareInfo");
                localStorage.removeItem("AddToOrder");

                if (response.isanonymous)
                    handleLogin(response.token, null, null, response.isanonymous, null, response.startModule);

                window.location.href = configData.Settings.Base_Url;
            }
        }

        onCallLogout();
    };

    const handleChangeModuleSelected = (moduleName) => {
        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            let currentUser2 = JSON.parse(jCurrentUser);

            currentUser2.selectModule = moduleName;

            let jCurrentUser2 = JSON.stringify(currentUser2);
            localStorage.setItem("CurrentUser", jCurrentUser2);
        }

        setModuleSelected(moduleName);
    }

    const setQuotationInfoSession = (quotationInfo) => {

        if (quotationInfo !== null && quotationInfo !== undefined) {
            let jCurrentQuotationInfo = JSON.stringify(quotationInfo);

            localStorage.removeItem("QuotationInfo");
            localStorage.setItem("QuotationInfo", jCurrentQuotationInfo);

            setQuotationInfo(quotationInfo);
        } else {
            localStorage.removeItem("QuotationInfo");
            setQuotationInfo({});
        }

    }

    const setAddToOrderSession = (addToOrder) => {
        if (addToOrder !== null && addToOrder !== undefined) {
            let jCurrentAddToOrder = JSON.stringify(addToOrder);

            localStorage.removeItem("AddToOrder");
            localStorage.setItem("AddToOrder", jCurrentAddToOrder);

            setAddToOrder(addToOrder);
        } else {
            localStorage.removeItem("AddToOrder");
            setAddToOrder({});
        }
    }


    const value = {
        onLogin: handleLogin,
        onLogout: handleLogout,
        onChangeModuleSelected: handleChangeModuleSelected,
        getModuleSelected: moduleSelected,
        getDefaultModule: defaultModule,
        getTotalCartItem: { totalItems: 0 },
        setQuotationInfoSession: setQuotationInfoSession,
        getQuotationInfoSession: quotationInfo,
        setAddToOrderSession: setAddToOrderSession,
        getAddToOrderSession: addToOrder
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};