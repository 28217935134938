import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';


export const CartTermsConditions = ({ handleTermsAndConditions, callIsCheckedAllTermsAndConditions }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [dynamicNotes, setDynamicNotes] = useState([]);

    // qui mi tengo via lo stato di ciascuna nota (noteId, checkType [no, facoltativo, obbligatorio], selected [true, false])
    const [selectedNotes, setSelectedNotes] = useState([]);


    useEffect(() => {
        const getTermsAndConditions = async () => {
            const response = callGetTermsAndConditions();
        }

        setIsLoading(true);
        getTermsAndConditions();
    }, []);

    async function callGetTermsAndConditions() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetTermsAndConditions/' + cultureName, requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setDynamicNotes(response.dynamicNotes);

            let sNotes = [];
            for (let i = 0; i < response.dynamicNotes.length; i++) {
                sNotes.push({ NoteId: response.dynamicNotes[i].noteId, CheckType: response.dynamicNotes[i].checkTypeId, CultureCode: cultureName, Selected: false });
            }
            setSelectedNotes(sNotes);
            handleTermsAndConditions(sNotes); // update parent page (cart)
        }

        setIsLoading(false);
    }


    const onChange = (checkboxId, noteId) => {
        let inputTarget = document.getElementById(checkboxId);

        // copy array
        let sNotes = [];
        for (let i = 0; i < selectedNotes.length; i++) {
            sNotes.push(selectedNotes[i]);
        }

        // update selected flag
        let selectedNoteIdx = sNotes.findIndex((x => x.NoteId === noteId));
        sNotes[selectedNoteIdx].Selected = inputTarget.checked;

        // save updated array
        setSelectedNotes(sNotes);

        // update parent page (cart)
        handleTermsAndConditions(sNotes);

        // verifica che tutte i termini e condizioni obbligatori siano checcati
        checkIsAllMandatoryTrue(inputTarget.checked);
    }

    function checkIsAllMandatoryTrue(noteId, isChecked) {
        let isAllChecked = true;

        if (dynamicNotes && dynamicNotes.length > 0) {
            let sNotes = [...selectedNotes];

            for (let i = 0; i < dynamicNotes.length; i++) {
                let myNote = dynamicNotes[i];

                if (myNote.NoteId === noteId && !isChecked) {
                    isAllChecked = false;
                }
                else if (myNote.checkTypeId !== 1 && myNote.NoteId !== noteId) {
                    let selectedNoteIdx = sNotes.findIndex((x => x.NoteId === noteId));
                    if (selectedNoteIdx > 0 && !sNotes[selectedNoteIdx].Selected) {
                        isAllChecked = false;
                    }
                }

                if (!isAllChecked)
                    break;

            }

        }

        callIsCheckedAllTermsAndConditions(isAllChecked);
    }

    const renderTermsAndConditions = () => {
        return (
            <>
                {dynamicNotes.map(x => {
                    return (
                        <div key={x.noteId} id={`noteId_${x.noteId}`} className="row">
                            <div className="col-1 mr-0 pr-0" style={{ maxWidth: '35px' }}>
                                {x.checkTypeId === 1
                                    ? (<></>)
                                    : (<><input id={`cb_${x.noteId}`} type="checkbox" onChange={e => onChange(e.target.id, x.noteId)} />&nbsp;</>)
                                }
                            </div>
                            <div className="col-11 ml-0 pl-0 text-justify">
                                 <label htmlFor={`cb_${x.noteId}`}><span className="document-txt-note" dangerouslySetInnerHTML={{ __html: (x.note) }}></span></label>
                            </div>
                        </div>
                    )
                })}
            </>
        );
    }


    return (
        <>
            <div>
                {isLoading
                    ? (<Loading textMsg={t('MyBook:Loading')} />)
                    : dynamicNotes && renderTermsAndConditions()
                }
            </div>
        </>
    )
}
