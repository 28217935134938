import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { getSearchParameterDate, getPassengerComposition, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { FlightLegsList } from "../../Product/Flight/components/FlightLegsList";
import { FlightItemList } from "../../Product/Flight/components/FlightItemList";
import { ProductFilter } from '../../Product/Common/Filter/ProductFilter';
import { ProductFilterByName } from '../../Product/Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../../Product/Common/Filter/ProductFilterByPrice';
import { ProductDestinationFilter } from '../../Product/Common/Filter/ProductDestinationFilter';
import { FlightChooseFare } from '../../Product/Flight/components/FlightChooseFare';
import { FlightPriceFareDetail } from '../../Product/Flight/components/FlightPriceFareDetail';
import { FlightFareRules } from '../../Product/Flight/components/FlightFareRules';
import { getFlightFareRules } from '../../../js/ProductService';
import { imageOnLoadFlight } from '../../../js/CDNUtility';
import { scrollTo, formatPrice, getWebsiteId } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";
import { convertHourInMilliseconds } from '../../../js/Utils.js';

export const FlightTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch, setModalOpenFareDetail, setModalOpenFlightFare }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    // avl
    const [avlCarpetId, setAvlCarpetId] = useState(null);
    const [flightAvailability, setFlightAvailability] = useState(null);

    // src
    const [srcCarpetId, setSrcCarpetId] = useState(null);
    const [srcItineraries, setSrcItineraries] = useState(null);
    const [showFlightList, setShowFlightList] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);
    const [srcItineraryUniqueId, setSrcItineraryUniqueId] = useState(null);
    const [srcItineraryFareUniqueId, setSrcItineraryFareUniqueId] = useState(null);
    // src - filters
    const [srcItinerariesFilters, setSrcItinerariesFilters] = useState(null);
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [showSrcItinerariesFilters, setShowSrcItinerariesFilters] = useState(false);
    const [rangePriceInfo, setRangePriceInfo] = useState({ min: "0", max: "5000", step: "50", currency: "EUR" });
    const [srcFilterSelected, setSrcFilterSelected] = useState({ rangePrice: null, pageNumber: 0, providers: [], baggages: [], stops: [], orderBy: 0, airportArrivals: [], airportDepartures: [], mktCarriers: [], flightTimeInfo: null });
    // avl - fares
    const [showFlightFareChoose, setShowFlightFareChoose] = useState(false);
    const [showFlightFareDetailPrice, setShowFlightFareDetailPrice] = useState(false);
    const [showFlightFareRules, setShowFlightFareRules] = useState(false);
    const [flightFareRules, setFlightFareRules] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        onFinishSearch(templateData.templateDataId, true);

        if (searchStarted) {
            callTemplateStartSearch(null, null);
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setSearchStarted(true);
        callTemplateStartSearch(null, null);
    }, [selectedProducts]);


    // availability functions
    async function callTemplateStartSearch(itineraryUniqueId, fareUniqueId) {
        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        let carpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.CarpetId : selectedProductData.AlternativeInfo.CarpetId
            : avlCarpetId;

        if (itineraryUniqueId) {
            carpetId = null;
        }

        if (itineraryUniqueId || fareUniqueId) {
            setIsLoading(true);
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, false, false, null);
        }

        let requestParam = {
            TemplateId: templateData.templateId,
            TemplateDataId: templateData.templateDataId,
            DepartureDate: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Flight.IdTipoProdotto, "CHECKIN"),
            PaxComposition: getPassengerComposition(searchParameters, configData.Settings.Products.Flight.IdTipoProdotto),
            Citizenship: searchParameters.citizenship,
            WebsiteId: getWebsiteId(),
            SessionToken: cookies.get("SessionToken"),
            CultureCode: cultureName,
            CarpetId: carpetId,
            SrcCarpetId: sCarpetId,
            DepartureCode: searchParameters.DepartureCode,
            DepartureType: searchParameters.DepartureType,
            ItineraryUniqueId: itineraryUniqueId,
            FareUniqueId: fareUniqueId,
            TemplateDuration: searchParameters.templateDuration
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Flight/TemplateStartSearch`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.flight) {
                isError = false;

                setFlightAvailability(response.flight);
                setAvlCarpetId(response.flight.carpetId);
                setSrcCarpetId(response.flight.srcCarpetId);

                let setDefaultOption = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                if (fareUniqueId) {
                    // setto la Fare scelta
                    let selectedFare = response.flight.fares.filter(x => x.uniqueId === fareUniqueId)[0];
                    let productSelection = `${response.flight.uniqueId}|${selectedFare.uniqueId}`;
                    let productPriceDetail = {
                        DisplayPrice: selectedFare.price.displayPrice,
                        NetPrice: selectedFare.price.displayNet,
                        Markup: selectedFare.price.agencyMarkupVal,
                        Fee: selectedFare.price.agencyFee,
                        Commission: selectedFare.price.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.flight.carpetId, response.flight.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }
                else if (setDefaultOption || itineraryUniqueId) {
                    // setto la Fare di default
                    let productSelection = `${response.flight.uniqueId}|${response.flight.fares[0].uniqueId}`;
                    let productPriceDetail = {
                        DisplayPrice: response.flight.fares[0].price.displayPrice,
                        NetPrice: response.flight.fares[0].price.displayNet,
                        Markup: response.flight.fares[0].price.agencyMarkupVal,
                        Fee: response.flight.fares[0].price.agencyFee,
                        Commission: response.flight.fares[0].price.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.flight.carpetId, response.flight.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }

                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    let productSelection = `${response.flight.uniqueId}|${response.flight.fares[0].uniqueId}`;
                    let productPriceDetail = {
                        DisplayPrice: response.flight.fares[0].price.displayPrice,
                        NetPrice: response.flight.fares[0].price.displayNet,
                        Markup: response.flight.fares[0].price.agencyMarkupVal,
                        Fee: response.flight.fares[0].price.agencyFee,
                        Commission: response.flight.fares[0].price.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.flight.carpetId, response.flight.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, requestParam.CarpetId, requestParam.SrcCarpetId, null, null, true, true, null);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }

    // src functions

    async function changeFlight(e) {
        let updFilters = { rangePrice: null, pageNumber: 0, providers: [], baggages: [], stops: [], orderBy: 0, airportArrivals: [], airportDepartures: [], mktCarriers: [], flightTimeInfo: null };
        setSrcFilterSelected(updFilters);
        getFlightList(e, 0, updFilters);
    }
    async function getFlightList(e, pageNumber, filters) {
        if (e) e.preventDefault();

        setShowFlightFareChoose(false);
        setShowFlightFareDetailPrice(false);
        setShowFlightFareRules(false);

        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        if (!filters) {
            filters = srcFilterSelected;
        }

        let requestParam = {
            cultureCode: cultureName,
            carpetId: sCarpetId,
            pageNumber: pageNumber ?? 0,
            pageSize: 10,
            stops: filters.stops,
            baggages: filters.baggages,
            flightNumber: filters.flightNumber,
            providers: filters.suppliers,
            rangePrice: filters.rangePrice,
            airportDepartures: filters.airportDepartures,
            airportArrivals: filters.airportArrivals,
            airlinesMkt: filters.mktCarriers,
            flightTimeInfo: filters.flightTimeInfo,
            orderBy: 0,
            showCost: false
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}flight/GetFlightList`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcItineraries(response);
            renderPaginator(response.totalFlights);

            await getFlightListFilters(sCarpetId);

            setShowFlightList(true);
        }
    }
    async function getFlightListFilters(carpetId) {
        let requestParam = { cultureCode: cultureName, carpetId: carpetId };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}flight/GetFlightListFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcItinerariesFilters(response);
        }
    }
    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / pageSize));
    }
    const handlePageClick = async e => {
        scrollTo(null, `flightList_${templateData.templateDataId}`);
        setPageSelected(e.selected);
        getFlightList(null, e.selected);
    };
    async function onSelectFlight(data) {
        setSrcItineraryUniqueId(data.itineraryId);
        setSrcItineraryFareUniqueId(data.fareId);

        callTemplateStartSearch(data.itineraryId, null);
        setFlightFareRules(null);

        scrollTo(null, `flightList_${templateData.templateDataId}`);
        setShowFlightList(false);
    }

    function callFilterValues(filters) {
        getFlightList(null, 0, filters);
    }
    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...srcFilterSelected };

        switch (type) {
            case 'FlightBaggages':
                {
                    filterSelectedUpdate.baggages = selectedItems;
                    break;
                }
            case 'FlightSuppliers':
                {
                    filterSelectedUpdate.suppliers = selectedItems;
                    break;
                }
            case 'FlightStops':
                {
                    filterSelectedUpdate.stops = selectedItems;
                    break;
                }
            case 'FlightMkt':
                {
                    filterSelectedUpdate.mktCarriers = selectedItems;
                    break;
                }

            default:
                break;
        }

        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterMultiDestinationTime(selectedItems, type) {
        let flightTimeInfo = { ...srcFilterSelected.flightTimeInfo };

        if (type === 'FlightDestinationTime') {

            if (!flightTimeInfo || !flightTimeInfo.flightTimeLegCriterias)
                flightTimeInfo = { flightTimeLegCriterias: selectedItems };

            for (let i = 0; i < flightTimeInfo.flightTimeLegCriterias.length; i++) {
                flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom = selectedItems[i].departureTimeFrom;
                flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo = selectedItems[i].departureTimeTo;
                flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom = selectedItems[i].arrivalTimeFrom;
                flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo = selectedItems[i].arrivalTimeTo;
            }
        }
        else if (type === 'FlightStops') {
            if (!flightTimeInfo || !flightTimeInfo.flightTimeLegCriterias)
                flightTimeInfo = { flightTimeLegCriterias: Array.from({ length: srcItinerariesFilters.destinationNames.length }, () => ({})) };

            for (let i = 0; i < flightTimeInfo.flightTimeLegCriterias.length; i++) {

                if (!flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom)
                    flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom = '2099-01-01 00:00:00';

                if (!flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo)
                    flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo = '2099-01-01 23:59:59';

                if (!flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom)
                    flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom = '2099-01-01 00:00:00';

                if (!flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo)
                    flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo = '2099-01-01 23:59:59';

                // Convert hour ("22:00") to milliseconds values
                flightTimeInfo.flightTimeLegCriterias[i].stopsDurationFrom = convertHourInMilliseconds(selectedItems[i].minStopDuration);
                flightTimeInfo.flightTimeLegCriterias[i].stopsDurationTo = convertHourInMilliseconds(selectedItems[i].maxStopDuration);
            }
        }

        let filterSelectedUpdate = {
            ...srcFilterSelected,
            flightTimeInfo: flightTimeInfo
        };

        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterAirportDeparture(selectedItems, type) {
        let filterSelectedUpdate = { ...srcFilterSelected, airportDepartures: selectedItems };
        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterAirportArrival(selectedItems, type) {
        let filterSelectedUpdate = { ...srcFilterSelected, airportArrivals: selectedItems };
        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterName(number) {
        let filterSelectedUpdate = { ...srcFilterSelected, flightNumber: number.toUpperCase() };
        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = { ...srcFilterSelected, rangePrice: rangePrice }
        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onClearFilter() {
        let filterSelectedUpdate = {
            ...srcFilterSelected,
            rangePrice: null,
            providers: [],
            baggages: [],
            stops: [],
            airportArrivals: [],
            airportDepartures: [],
            mktCarriers: []
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setSrcFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // avl functions
    function openFlightFareChoose(e) {
        if (e) e.preventDefault();

        if (showFlightFareChoose) {
            setShowFlightFareChoose(false);
            return;
        }

        setShowFlightFareChoose(true);
        setShowFlightList(false);
        setShowFlightFareDetailPrice(false);
        setShowFlightFareRules(false);
    }
    function openFlightDetailPrice(e) {
        if (e) e.preventDefault();

        if (showFlightFareDetailPrice) {
            setShowFlightFareDetailPrice(false);
            return;
        }

        setShowFlightFareDetailPrice(true);
        setShowFlightList(false);
        setShowFlightFareChoose(false);
        setShowFlightFareRules(false);
    }
    function selectOtherFare(selectedFareUniqueId) {
        setSrcItineraryFareUniqueId(selectedFareUniqueId);
        callTemplateStartSearch(null, selectedFareUniqueId);
        setFlightFareRules(null);
        setShowFlightFareChoose(false);
    }
    async function getFareRules(e) {
        if (e) e.preventDefault();

        setShowFlightList(false);
        setShowFlightFareChoose(false);
        setShowFlightFareDetailPrice(false);

        if (showFlightFareRules) {
            setShowFlightFareRules(false);
            return;
        }

        if (flightFareRules) {
            setShowFlightFareRules(true);
            return;
        }

        setIsLoading(true);

        let requestParam = {
            CultureCode: cultureName,
            AvlCarpetId: selectedProductData.SelectedInfo.CarpetId,
            SelectedItineraryUniqueId: selectedProductData.SelectedInfo.ProductSelection.split("|")[0],
            SelectedFareUniqueId: selectedProductData.SelectedInfo.ProductSelection.split("|")[1]
        };

        const responseFareDetail = await getFlightFareRules(requestParam);
        if (responseFareDetail.success) {
            setFlightFareRules(responseFareDetail.fareRules);
            setShowFlightFareRules(true);
        }

        setIsLoading(false);
    }

    // buttons
    const onButtonSelect = () => {
        // funzione usata quando si seleziona un prodotto opzionale o in alternativa, quindi viene selezionata la option con prezzo piu basso
        let productSelection = `${flightAvailability.uniqueId}|${flightAvailability.fares[0].uniqueId}`;
        let productPriceDetail = {
            DisplayPrice: flightAvailability.fares[0].price.displayPrice,
            NetPrice: flightAvailability.fares[0].price.displayNet,
            Markup: flightAvailability.fares[0].price.agencyMarkupVal,
            Fee: flightAvailability.fares[0].price.agencyFee,
            Commission: flightAvailability.fares[0].price.commission
        };
        onSelectAlternative(templateData.templateDataId, selectedProductData.SelectedId, productSelection, productPriceDetail, flightAvailability.carpetId, flightAvailability.srcCarpetId);
    }
    const onButtonUnselect = () => {
        onUnselectOptional(templateData.templateDataId);
    }

    // pricing
    const getTotalPriceDiff = (fares) => {
        // funzione usata per calcolare la differenza di prezzo tra la option scelta e la best del prodotto opzionale/in alternativa
        let totalPrice = fares[0].price.displayPrice;

        if (!selectedProductData || !selectedProductData.SelectedTotalPrice) {
            if (isOptional) {
                let formattedTotalPrice = formatPrice(totalPrice, fares[0].price.valutaCliente, cultureName);
                return `+${formattedTotalPrice}`;
            }

            return (<></>);
        }

        let diff = totalPrice - selectedProductData.SelectedTotalPrice.DisplayPrice;
        if (diff === 0)
            return "+ " + formatPrice(diff, fares[0].price.valutaCliente, cultureName);

        let formattedPrice = formatPrice(diff, fares[0].price.valutaCliente, cultureName);

        return `${diff > 0 ? "+" : ""}${formattedPrice}`;
    }

    // render
    const renderSelectedFare = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!flightAvailability || !flightAvailability.fares || flightAvailability.fares.length === 0)
            return (<></>);

        if ((!isAlternative && !isOptional) || isOptional) {
            let selectedFare = flightAvailability.fares[0];
            if (srcItineraryFareUniqueId) {
                let items = flightAvailability.fares.filter(x => x.uniqueId === srcItineraryFareUniqueId);
                if (items && items.length > 0)
                    selectedFare = items[0];
            }

            return (
                <React.Fragment>
                    {flightAvailability.legs && flightAvailability.legs.length > 0 && <FlightItemList
                        item={flightAvailability}
                        fareId={selectedFare.uniqueId}
                        isTemplate={true}
                        isViewDetail={true}
                        showPriceBar={false} />
                    }

                    <div className="col-12 text-right">
                        {flightAvailability.status && flightAvailability.status === 3 && <div className="text-info mr-2">OnRequest</div>}
                    </div>
                </React.Fragment>
            );
        } else {
            return (<></>);
        }
    }
    const renderSupplier = () => {
        if (isError || !flightAvailability || !flightAvailability.fares || flightAvailability.fares.length === 0)
            return (<></>);

        let selectedFare = flightAvailability.fares[0];
        if (srcItineraryFareUniqueId) {
            let items = flightAvailability.fares.filter(x => x.uniqueId === srcItineraryFareUniqueId);
            if (items && items.length > 0)
                selectedFare = items[0];
        }

        return selectedFare && selectedFare.supplier
            ? (<span className="text-warning small d-block mr-2"><var>{selectedFare.supplier.supplierName}</var></span>)
            : (<></>);
    }


    return (
        <>
            {templateData &&
                <React.Fragment>
                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {flightAvailability && flightAvailability.itineraryName}<br />
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadFlight()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                <div className="myBook-card-body">
                                    {isLoading
                                        ? (
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <span><data m3lab="Product.Flight.Item">{t(`Product:Flight:Item`)}</data></span>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                        <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                                    </div>
                                                </div>
                                                <div className="h5">
                                                    <var>{t(`Product:Flight:Item`)} {templateData.product.itineraryName}</var>
                                                </div>
                                                <div>
                                                    <div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div> {t(`Template:Loading`)}
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <>
                                                {/* Menu Functions */}
                                                <div className="row float-end">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="btn-group text-right float-end pr-2">
                                                            {renderSupplier()}
                                                            <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                                <data m3ico="MenuFunctions">
                                                                    <M3Icon iconName="MenuFunctions" />
                                                                </data>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                                {flightAvailability && flightAvailability.fares && flightAvailability.fares.length > 0 && flightAvailability.fares[0].isDmc === false &&
                                                                    <li>
                                                                        <a id={`aShowFlightList_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => changeFlight(e)}>
                                                                            <data m3ico="Flight icon-10"><M3Icon iconName="Flight" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.FlightChange">{t(`ActionMenu:FlightChange`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }

                                                                {flightAvailability && flightAvailability.fares && flightAvailability.fares.length > 1 &&
                                                                    <li>
                                                                        <a id={`aShowFlightSelectFare_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => openFlightFareChoose(e)}>
                                                                            <data m3ico="FlightAddService icon-10"><M3Icon iconName="FlightAddService" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.FlightFareChange">{t(`ActionMenu:FlightFareChange`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }

                                                                <li>
                                                                    <a id={`aShowFlightFareDetailPrice_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => openFlightDetailPrice(e)}>
                                                                        <data m3ico="FlightFareTable icon-10"><M3Icon iconName="FlightFareTable" externalClass="icon-10 small text-gray-600" /></data>
                                                                        <label><data m3lab="ActionMenu.FlightFareDetailPrice">{t(`ActionMenu:FlightFareDetailPrice`)}</data></label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a id={`aShowFlightFareRules_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => getFareRules(e)}>
                                                                        <data m3ico="FlightRoule icon-10"><M3Icon iconName="FlightRoule" externalClass="icon-10 small text-gray-600" /></data>
                                                                        <label><data m3lab="ActionMenu.FlightFareRules">{t(`ActionMenu:FlightFareRules`)}</data></label>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {renderSelectedFare()}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            {/* Button Remove when Optional */}
                            {!isLoading && templateData.inclusion === TemplateInclusion.Optional && (
                                <>
                                    <div>
                                        <div className="float-end small text-blue">
                                            <button id={`btnUnselect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonUnselect()}>
                                                <span className="text-danger">
                                                    <data m3lab="Template.Delete">Rimuovi</data>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment >
                    }

                    {(isAlternative || isOptional) && !isLoading && !isError &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                        <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadFlight()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}></div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                        <div className="w-100">
                                            <div className="h6 m-0">
                                                <var>{t(`Product:Flight:Item`)} {templateData.product.itineraryName}</var>
                                            </div>

                                            {!isLoading && renderSelectedFare()}
                                        </div>
                                    </div>

                                    {!isError &&
                                        <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                            <button id={`btnSelect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonSelect()}>
                                                {isLoading && <><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingAlternatives`)}</>}

                                                {!isLoading && flightAvailability && flightAvailability.fares && flightAvailability.fares.length > 0 &&
                                                    <React.Fragment>
                                                        <data m3lab="Template.Option">{isAlternative ? t(`Template:Option`) : t(`Template:Add`)}</data>
                                                        <div>
                                                            {getTotalPriceDiff(flightAvailability.fares)}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment >
                    }

                    {/* Flight List */}
                    {srcItineraries && srcItineraries.flights && srcItineraries.flights.length > 0 && showFlightList &&
                        <React.Fragment>
                            {/* Filters */}
                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-2 mb-2">
                                <button className="btn btn-sm tp-btn-select mb-1" onClick={e => { e.preventDefault(); setShowSrcItinerariesFilters(!showSrcItinerariesFilters); }}>
                                    {t(`Button:Filter`)}
                                    <data m3ico="Filter icon-15 text-bluesoft"><M3Icon iconName="Filter" externalClass="icon-15 text-bluesoft" hasLabel={false} /></data>
                                </button>
                            </div>
                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-2 mb-2">&nbsp;</div>
                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-2 mb-2 text-right">
                                <button className="btn btn-sm tp-btn-select mb-1" onClick={e => { e.preventDefault(); setShowFlightList(!showFlightList); }}>
                                    {t(`Button:Close`)}
                                    <data m3ico="Close icon-15 text-bluesoft"><M3Icon iconName="Close" externalClass="icon-15 text-bluesoft" hasLabel={false} /></data>
                                </button>
                            </div>

                            {showSrcItinerariesFilters &&
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                    {/* Codice */}
                                    <ProductFilterByName
                                        items={srcItinerariesFilters.flightNumbers}
                                        label="codice volo"
                                        type="FlightNumber"
                                        onApplyFilter={onApplyFilterName}
                                        resetSelectedItem={isResetSelectedItem} />

                                    <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                        <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                    </button>

                                    {/* Price */}
                                    <ProductFilterByPrice
                                        rangePriceInfo={rangePriceInfo}
                                        onApplyFilter={onApplyFilterPrice}
                                        resetSelectedItem={isResetSelectedItem} />

                                    {/* Bagagli */}
                                    <ProductFilter
                                        items={srcItinerariesFilters.baggages}
                                        label="Product:Flight:Filter:Baggages"
                                        type="FlightBaggages"
                                        iconTitle="luggage"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    {/* Scali */}
                                    <ProductFilter
                                        items={srcItinerariesFilters.stops}
                                        label="Product:Flight:Filter:Stops"
                                        type="FlightStops"
                                        iconTitle="connecting_airports"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem}
                                        subFilterOptions={{ subItems: srcItinerariesFilters.destinationNames, onApplyFilter: onApplyFilterMultiDestinationTime }} />

                                    {/* Aeroporti */}
                                    <ProductDestinationFilter
                                        departureAirports={srcItinerariesFilters.departureAirports}
                                        arrivalAirports={srcItinerariesFilters.arrivalAirports}
                                        label="Product:Flight:Filter:Airports"
                                        iconTitle="flight"
                                        type=""
                                        onApplyFilterAirportDeparture={onApplyFilterAirportDeparture}
                                        onApplyFilterAirportArrival={onApplyFilterAirportArrival}
                                        resetSelectedItem={isResetSelectedItem} />

                                    {/* Compagnia aerea */}
                                    <ProductFilter
                                        items={srcItinerariesFilters.mktCarriers}
                                        label="Product:Flight:Filter:FlightCompany"
                                        type="FlightMkt"
                                        iconTitle="airlines"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    {/* GDS */}
                                    <ProductFilter
                                        items={srcItinerariesFilters.suppliers}
                                        label="Product:Flight:Filter:GDS"
                                        type="FlightSuppliers"
                                        iconTitle="shop_two"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />
                                </div>
                            }

                            {/* Search List */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {/* Items */}
                                <a id={`flightList_${templateData.templateDataId}`} href></a>

                                {srcItineraries.flights.map((item, key) => {
                                    return <FlightItemList key={key}
                                        item={item}
                                        showPriceBar={false}
                                        extraParam={{
                                            templateInfo:
                                            {
                                                templateId: templateData.templateId,
                                                selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null,
                                                showSelectButton: true
                                            }
                                        }}
                                        modalOpenProductId={setModalOpenFlightFare}
                                        modalOpenFareDetail={setModalOpenFareDetail}
                                        onClickSearch={onSelectFlight}
                                    />
                                })}

                                { /* Paginator */}
                                {srcItineraries && srcItineraries.flights && srcItineraries.flights.length > 0 && showFlightList &&
                                    <>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </div>
                        </React.Fragment>
                    }

                    {/* Flight Fare Detail */}
                    {showFlightFareChoose &&
                        <>
                            {flightAvailability && flightAvailability.fares && flightAvailability.fares.length > 0 &&
                                flightAvailability.fares.filter(x => x.uniqueId !== Number(selectedProductData.SelectedInfo.ProductSelection.split("|")[1])).map((fare, key) => {
                                    let selectedFareUniqueId = Number(selectedProductData.SelectedInfo.ProductSelection.split("|")[1]);
                                    let selectedFarePrice = selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice;
                                    let selectedFare = flightAvailability.fares.filter(x => x.uniqueId === selectedFareUniqueId)[0];

                                    return (
                                        <FlightChooseFare
                                            key={key}
                                            fare={fare.uniqueId === selectedFareUniqueId ? (flightAvailability && flightAvailability.fares && flightAvailability.fares[0]) : fare}
                                            fareSelected={selectedFare}
                                            selectedFarePrice={selectedFarePrice}
                                            handleClickFare={selectOtherFare} />
                                    )
                                })}
                        </>
                    }

                    {/* Flight Fare Detail Price */}
                    {showFlightFareDetailPrice &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            {flightAvailability.legs && flightAvailability.legs.length > 0 && flightAvailability.legs.map((leg, key) => {
                                let selectedFareUniqueId = Number(selectedProductData.SelectedInfo.ProductSelection.split("|")[1]);
                                let selectedFare = [flightAvailability.fares.filter(x => x.uniqueId === selectedFareUniqueId)[0]];

                                return (
                                    <FlightPriceFareDetail
                                        key={key}
                                        fares={selectedFare}
                                        leg={leg}
                                        legKey={key} />
                                )
                            })}
                        </div>
                    }

                    {/* Flight Fare Rules */}
                    {showFlightFareRules && flightFareRules &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h6>{t(`ActionMenu:FareRules`)}</h6>
                            <ul className="list-group list-group-flush" id="Conditions">
                                <FlightFareRules fareRules={flightFareRules} />
                            </ul>
                        </div>
                    }
                </React.Fragment>
            }

        </>
    );
}
