import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { getSearchParameterDate, getPassengerComposition, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { imageOnLoadCarRental } from '../../../js/CDNUtility';
import { scrollTo, formatPrice, getWebsiteId } from '../../../js/Utils.js';
import { CarItemList } from '../../Product/Car/components/CarItemList';
import { CarOptions } from '../../Product/Car/components/CarOptions';
import configData from "../../../appsettings.json";

export const CarRentalTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    // src
    const [srcCarpetId, setSrcCarpetId] = useState(null);
    const [srcCarInfos, setSrcCarInfos] = useState(null);
    const [showCarList, setShowCarList] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);
    const [typeOfSearch, setTypeOfSearch] = useState(null);
    const [srcCarId, setSrcCarId] = useState(null);
    const [optionProductCode, setOptionProductCode] = useState(null);
    // src - filters
    const [srcCarFilters, setSrcCarFilters] = useState(null);
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [showSrcCarFilters, setShowSrcCarFilters] = useState(false);
    const [rangePriceInfo, setRangePriceInfo] = useState({ min: "0", max: "5000", step: "50", currency: "EUR" });
    const [srcFilterSelected, setSrcFilterSelected] = useState({ rangePrice: null, officeCode: "", pageNumber: 0, orderBy: 0, providers: [], classes: [], types: [], onBoardEquipment: [], pickupDestinations: [], dropoffDestinations: [], carOptions: [], pickupOffices: [], dropoffOffices: [], suppliers: [] });

    // avl - options
    const [showCarOptionChoose, setShowCarOptionChoose] = useState(false);

    const [carRentalDetail, setCarRentalDetail] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        onFinishSearch(templateData.templateDataId, true);

        if (searchStarted) {
            callTemplateStartSearch(null);
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setSearchStarted(true);
        callTemplateStartSearch(null);
    }, [selectedProducts]);


    // start search
    async function callTemplateStartSearch(carId, optionProductCode) {
        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        if (carId || optionProductCode) {
            setIsLoading(true);
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, false, false, null);
        }

        let requestParam = {
            TemplateId: templateData.templateId,
            TemplateDataId: templateData.templateDataId,
            DepartureDate: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.CarRental.IdTipoProdotto, "CHECKIN"),
            PaxComposition: getPassengerComposition(searchParameters, configData.Settings.Products.CarRental.IdTipoProdotto),
            Citizenship: searchParameters.citizenship,
            WebsiteId: getWebsiteId(),
            SessionToken: cookies.get("SessionToken"),
            CultureCode: cultureName,
            SrcCarpetId: sCarpetId,
            CarId: carId,
            OptionProductCode: optionProductCode,
            TemplateDuration: searchParameters.templateDuration
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}CarRental/TemplateStartSearch`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.carRental) {
                isError = false;

                setSrcCarpetId(response.carRental.srcCarpetId);
                setCarRentalDetail(response.carRental);
                setSrcCarId(response.carRental.id);
                setTypeOfSearch(response.typeOfSearch);

                let setDefaultOption = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                if (optionProductCode) {
                    // setto la Option scelta
                    let selectedOption = response.carRental.options.filter(x => x.productCode === optionProductCode)[0];
                    let productSelection = `${response.carRental.id}|${selectedOption.productCode}`;
                    let productPriceDetail = {
                        DisplayPrice: selectedOption.pricing.displayPrice,
                        NetPrice: selectedOption.pricing.netPrice,
                        Markup: selectedOption.pricing.agencyMarkupVal,
                        Fee: selectedOption.pricing.agencyFee,
                        Commission: selectedOption.pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.carRental.srcCarpetId, response.carRental.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }
                else if (setDefaultOption || carId) { // setto la Car di default
                    // setto la Option di default
                    let productSelection = `${response.carRental.id}|${response.carRental.options[0].productCode}`;
                    let productPriceDetail = {
                        DisplayPrice: response.carRental.options[0].pricing.displayPrice,
                        NetPrice: response.carRental.options[0].pricing.networkPrice,
                        Markup: response.carRental.options[0].pricing.agencyMarkupVal,
                        Fee: response.carRental.options[0].pricing.agencyFee,
                        Commission: response.carRental.options[0].pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.carRental.srcCarpetId, response.carRental.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }

                // setto DefaultAlternativePrice
                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    let productSelection = `${response.carRental.id}|${response.carRental.options[0].productCode}`;
                    let productPriceDetail = {
                        DisplayPrice: response.carRental.options[0].pricing.displayPrice,
                        NetPrice: response.carRental.options[0].pricing.displayNet,
                        Markup: response.carRental.options[0].pricing.agencyMarkupVal,
                        Fee: response.carRental.options[0].pricing.agencyFee,
                        Commission: response.carRental.options[0].pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.carRental.srcCarpetId, response.carRental.srcCarpetId, productSelection, productPriceDetail, true, false, null);
                }
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true, null);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }

    // src functions
    async function getCarList(e, pageNumber, filters) {
        if (e) e.preventDefault();

        setShowCarOptionChoose(false);

        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        if (!filters) {
            filters = srcFilterSelected;
        }

        if (typeOfSearch === "CarOffice" && carRentalDetail && carRentalDetail.pickUpInfo) {
            filters.officeCode = carRentalDetail.pickUpInfo.carOfficeCode;
        }

        let requestParam = {
            cultureCode: cultureName,
            carpetId: sCarpetId,
            pageNumber: pageNumber ?? 0,
            pageSize: 10,
            officeCode: filters.officeCode,
            carName: filters.carName,
            providers: filters.providers,
            classes: filters.classes,
            types: filters.types,
            onBoardEquipment: filters.onBoardEquipment,
            pickupDestinations: filters.pickupDestinations,
            dropoffDestinations: filters.dropoffDestinations,
            carOptions: filters.carOptions,
            pickupOffices: filters.pickupOffices,
            dropoffOffices: filters.dropoffOffices,
            suppliers: filters.suppliers,
            rangePrice: filters.rangePrice,
            orderBy: 0,
            showCost: false
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}CarRental/GetCarRentalList`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcCarInfos(response);
            renderPaginator(response.totalCarRentals);

            await getCarListFilters(sCarpetId);

            setShowCarList(true);
        }
    }
    async function getCarListFilters(carpetId) {
        let requestParam = { cultureCode: cultureName, carpetId: carpetId };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}CarRental/GetCarRentalListFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcCarFilters(response);
        }
    }
    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / pageSize));
    }
    const handlePageClick = async e => {
        scrollTo(null, `carList_${templateData.templateDataId}`);
        setPageSelected(e.selected);
        getCarList(null, e.selected);
    };
    async function onSelectCar(data) {
        setSrcCarId(data);
        callTemplateStartSearch(data, null);

        scrollTo(null, `carList_${templateData.templateDataId}`);
        setShowCarList(false);
    }

    // avl functions
    function openCarOptionChoose(e) {
        if (e) e.preventDefault();

        if (showCarOptionChoose) {
            setShowCarOptionChoose(false);
            return;
        }

        setShowCarOptionChoose(true);
    }
    function selectOtherOption(selectedOptionProductCode) {
        setOptionProductCode(selectedOptionProductCode.productCode);
        callTemplateStartSearch(srcCarId, selectedOptionProductCode.productCode);
        setShowCarOptionChoose(false);
    }

    // buttons
    const onButtonSelect = () => {
        // funzione usata quando si seleziona un prodotto opzionale o in alternativa, quindi viene selezionata la option con prezzo piu basso
        let productSelection = `${carRentalDetail.id}|${carRentalDetail.options[0].productCode}`;
        let productPriceDetail = {
            DisplayPrice: carRentalDetail.options[0].pricing.displayPrice,
            NetPrice: carRentalDetail.options[0].pricing.networkPrice,
            Markup: carRentalDetail.options[0].pricing.agencyMarkupVal,
            Fee: carRentalDetail.options[0].pricing.agencyFee,
            Commission: carRentalDetail.options[0].pricing.commission
        };
        onSelectAlternative(templateData.templateDataId, selectedProductData.SelectedId, productSelection, productPriceDetail, carRentalDetail.srcCarpetId, carRentalDetail.srcCarpetId);
    }
    const onButtonUnselect = () => {
        onUnselectOptional(templateData.templateDataId);
    }

    // pricing
    const getTotalPriceDiff = (options) => {
        // funzione usata per calcolare la differenza di prezzo tra la option scelta e la best del prodotto opzionale/in alternativa
        let totalPrice = options[0].pricing.displayPrice;

        if (!selectedProductData || !selectedProductData.SelectedTotalPrice) {
            if (isOptional) {
                let formattedTotalPrice = formatPrice(totalPrice, options[0].pricing.valutaCliente, cultureName);
                return `+${formattedTotalPrice}`;
            }

            return (<></>);
        }

        let diff = totalPrice - selectedProductData.SelectedTotalPrice.DisplayPrice;
        if (diff === 0)
            return "+ " + formatPrice(diff, options[0].pricing.valutaCliente, cultureName);

        let formattedPrice = formatPrice(diff, options[0].pricing.valutaCliente, cultureName);

        return `${diff > 0 ? "+" : ""}${formattedPrice}`;
    }

    // render
    const renderSelectedOption = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!carRentalDetail || !carRentalDetail.options || carRentalDetail.options.length === 0)
            return (<></>);

        if ((!isAlternative && !isOptional) || isOptional) {
            return (
                <React.Fragment>
                    <CarItemList
                        item={carRentalDetail}
                        showPriceBar={false}
                        showCost={false}
                    />
                </React.Fragment>
            );
        } else {
            return (<></>);
        }
    }
    const renderSupplier = () => {
        if (isError || !carRentalDetail || !carRentalDetail.options || carRentalDetail.options.length === 0)
            return (<></>);

        return (
            carRentalDetail.supplier ? <span className="text-warning small d-block mr-2"><var>{carRentalDetail.supplier.supplierName}</var></span> : <></>
        );
    }


    return (
        <>
            {templateData &&
                <React.Fragment>
                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            {isLoading && <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadCarRental()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>}
                            <div className={isLoading ? "col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                                <div className="myBook-card-body">
                                    {isLoading
                                        ? (
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <span><data m3lab="Product.RentalCar.Item">{t(`Product:RentCar:Item`)}</data></span>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                        <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                                    </div>
                                                </div>
                                                <div className="h5">
                                                    <var>{t(`Product:RentCar:Item`)} {templateData.product.carName}</var>
                                                </div>
                                                <div>
                                                    <div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div> {t(`Template:Loading`)}
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <>
                                                {/* Menu Functions */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <div className="btn-group text-right float-end pr-2">
                                                            {renderSupplier()}
                                                            <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                                <data m3ico="MenuFunctions">
                                                                    <M3Icon iconName="MenuFunctions" />
                                                                </data>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                                {carRentalDetail &&
                                                                    <li>
                                                                        <a id={`aShowCarList_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => getCarList(e)}>
                                                                            <data m3ico="RentCar icon-10"><M3Icon iconName="RentCar" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.CarRentalChange">{t(`ActionMenu:CarRentalChange`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }
                                                                {carRentalDetail && carRentalDetail.options && carRentalDetail.options.length > 0 &&
                                                                    <li>
                                                                        <a id={`aShowCarSelectOption_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => openCarOptionChoose(e)}>
                                                                            <data m3ico="RentCar icon-10"><M3Icon iconName="RentCar" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.CarRentalOptionChange">{t(`ActionMenu:CarRentalOptionChange`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        {renderSelectedOption()}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            {/* Button Remove when Optional */}
                            {!isLoading && templateData.inclusion === TemplateInclusion.Optional && (
                                <>
                                    <div>
                                        <div className="float-end small text-blue">
                                            <button id={`btnUnselect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonUnselect()}>
                                                <span className="text-danger">
                                                    <data m3lab="Template.Delete">Rimuovi</data>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment >
                    }

                    {(isAlternative || isOptional) && !isLoading && !isError &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="w-100">
                                            <div className="h6 m-0">
                                                <var>{t(`Product:RentCar:Item`)} {templateData.product.pickUpInfo.officeName}</var>
                                            </div>

                                            {!isLoading && renderSelectedOption()}
                                        </div>
                                    </div>

                                    {!isError &&
                                        <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                            <button id={`btnSelect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonSelect()}>
                                                {isLoading && <><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingAlternatives`)}</>}

                                                {!isLoading && carRentalDetail && carRentalDetail.options && carRentalDetail.options.length > 0 &&
                                                    <React.Fragment>
                                                        <data m3lab="Template.Option">{isAlternative ? t(`Template:Option`) : t(`Template:Add`)}</data>
                                                        <div>
                                                            {getTotalPriceDiff(carRentalDetail.options)}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment >
                    }

                    {/* Car List */}
                    {srcCarInfos && srcCarInfos.carRentals && srcCarInfos.carRentals.length > 0 && showCarList &&
                        <React.Fragment>
                            {/* Filters */}


                            {/* Search List */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {/* Items */}
                                <a id={`carList_${templateData.templateDataId}`} href></a>

                                {srcCarInfos.carRentals.map((item, key) => {
                                    return <CarItemList key={key}
                                        item={item}
                                        showPriceBar={false}
                                        extraParam={{ templateInfo: { templateId: templateData.templateId, selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null } }}
                                        modalOpenProductId={null}
                                        onClickSearch={onSelectCar}
                                    />
                                })}

                                { /* Paginator */}
                                {srcCarInfos && srcCarInfos.carRentals && srcCarInfos.carRentals.length > 0 && showCarList &&
                                    <>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </div>
                        </React.Fragment>
                    }

                    {/* Car Option */}
                    {showCarOptionChoose &&
                        <>
                        {carRentalDetail && carRentalDetail.options && carRentalDetail.options.length > 0 &&
                            carRentalDetail.options.filter(x => x.productCode !== selectedProductData.SelectedInfo.ProductSelection.split("|")[1]).map((option, key) => {
                                let selectedOptionProductCode = selectedProductData.SelectedInfo.ProductSelection.split("|")[1];
                                let selectedOptionPrice = selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice;
                                //let selectedOption = carRentalDetail.options.filter(x => x.productCode === selectedOptionProductCode)[0];

                                return (
                                    <CarOptions
                                        key={key}
                                        option={option.productCode === selectedOptionProductCode ? (carRentalDetail && carRentalDetail.options && carRentalDetail.options[0]) : option}
                                        productCodeSelected={selectedOptionProductCode}
                                        idx={key}
                                        selectedPrice={selectedOptionPrice}
                                        handleClickOption={selectOtherOption} />
                                )
                            })}
                        </>
                    }

                </React.Fragment>
            }
        </>
    );
}
