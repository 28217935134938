import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { TemplateStepProduct } from "./TemplateStepProduct";
import { TemplateInclusion } from '../TemplateUtils';
import { formatDateTime } from '../../../js/Utils';
import configData from "../../../appsettings.json";

export const TemplateSteps = ({ template, steps, searchParameters, selectedProducts, setSelectedProducts }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [templateDatas, setTemplateData] = useState([]);


    useEffect(() => {
        let templateDatas = [];
        let excludeDefault = false;

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].templateData && steps[i].templateData.length > 0) {
                for (let j = 0; j < steps[i].templateData.length; j++) {
                    let item = steps[i].templateData[j];

                    if (template && template.templateType === 2) {
                        if (item) {
                            if (item.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                                let updDaysRange = [];
                                for (let k = 0; k <= item.daysRange.length; k++) {
                                    if (item.daysRange[k] <= searchParameters.templateDuration) {
                                        updDaysRange.push(item.daysRange[k]);
                                    } else {
                                        updDaysRange.push(searchParameters.templateDuration);
                                    }
                                }

                                if (updDaysRange.length === 0)
                                    updDaysRange = [searchParameters.templateDuration];

                                const distinctBy = (arr) => [...new Set(arr)];
                                updDaysRange = distinctBy(updDaysRange);
                                item.daysRange = updDaysRange;
                            }
                            else if (item.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
                                let updDaysRange = [];
                                let startDate = item.daysRange[0];
                                for (let k = startDate; k <= searchParameters.templateDuration; k++) {
                                    updDaysRange.push(k);
                                }

                                item.daysRange = updDaysRange;
                            }
                            else if (item.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
                                let updDaysRange = [];
                                for (let k = 0; k <= item.daysRange.length; k++) {
                                    if (item.daysRange[k] <= searchParameters.templateDuration) {
                                        updDaysRange.push(item.daysRange[k]);
                                    } else {
                                        updDaysRange.push(searchParameters.templateDuration);
                                    }
                                }

                                if (updDaysRange.length === 0)
                                    updDaysRange = [searchParameters.templateDuration];

                                const distinctBy = (arr) => [...new Set(arr)];
                                updDaysRange = distinctBy(updDaysRange);

                                item.daysRange = updDaysRange;
                            } else {
                                item.excludeProduct = item.daysRange[0] > searchParameters.templateDuration;
                            }

                            if (item.validTemplateDurationSearch && item.validTemplateDurationSearch.length > 0 && !item.excludeProduct)
                                item.excludeProduct = !item.validTemplateDurationSearch.some(x => x === searchParameters.templateDuration);
                        }
                    }

                    if (!item.excludeProduct) {
                        if (excludeDefault) {
                            item.isDefault = true;
                            excludeDefault = false;
                        }

                        templateDatas.push(item);

                    } else {
                        if (item.isDefault) {
                            excludeDefault = true;
                        }

                    }

                }
            }
        }

        setTemplateData(templateDatas);
    }, [steps, template]);

    function getStepStartDate(stepId) {
        let step = steps.filter(x => x.step === stepId)[0];
        let minDateRange = 0;

        for (let i = 0; i < step.templateData.length; i++) {
            if (step.templateData[i].daysRange && step.templateData[i].daysRange.length > 0 && !step.templateData[i].excludeProduct) {
                let templateDataMinDayRange = step.templateData[i].daysRange[0];
                if (minDateRange === 0) {
                    minDateRange = templateDataMinDayRange;
                } else {
                    if (templateDataMinDayRange < minDateRange) minDateRange = templateDataMinDayRange;
                }
            }
        }

        minDateRange = minDateRange - 1; // num days to sum to checkIn

        let checkInStep = searchParameters.checkIn;
        let newDate = new Date(checkInStep);
        newDate = new Date(newDate.setDate(newDate.getDate() + minDateRange));
        checkInStep = formatDateTime(newDate, cultureName, { noYear: true });

        return checkInStep;
    }
    function getStepEndDate(stepId) {
        let step = steps.filter(x => x.step === stepId)[0];
        let maxDateRange = 0;

        for (let i = 0; i < step.templateData.length; i++) {
            if (step.templateData[i].daysRange && step.templateData[i].daysRange.length > 0 && !step.templateData[i].excludeProduct) {
                let templateDataMaxDayRange = step.templateData[i].daysRange[step.templateData[i].daysRange.length - 1];

                // tipo Tour guardo la durationTime
                if (step.templateData[i].productType === configData.Settings.Products.Tour.IdTipoProdotto && step.templateData[i].product.durationTime) {
                    // Tour do not have the end date in daysRange, so we compute the end date starting from start date + the duration
                    maxDateRange = templateDataMaxDayRange + step.templateData[i].product.durationTime;
                }

                if (maxDateRange === 0) {
                    maxDateRange = templateDataMaxDayRange;
                } else {
                    if (templateDataMaxDayRange > maxDateRange) maxDateRange = templateDataMaxDayRange;
                }
            }
        }

        maxDateRange = maxDateRange - 1; // num days to sum to checkOut

        let checkInStep = searchParameters.checkIn;
        let newDate = new Date(checkInStep);
        newDate = new Date(newDate.setDate(newDate.getDate() + maxDateRange));
        checkInStep = formatDateTime(newDate, cultureName, { noYear: true });

        return checkInStep;
    }

    const renderTemplateData = (templateDatas) => {
        // qui ho tutti i TemplateDatas di uno Step
        // li raggruppo in base al tipo prodotto e al tipo di inclusion (0 e 1 vanno singolarmente, 2 va insieme alle alternative)

        templateDatas = templateDatas.filter(x => !x.excludeProduct);

        let tmpGrpTemplateDatas = [];
        for (let i = 0; i < templateDatas.length; i++) {
            if (tmpGrpTemplateDatas.length === 0 || tmpGrpTemplateDatas.findIndex(x => x.productType === templateDatas[i].productType) < 0) {
                tmpGrpTemplateDatas.push({ productType: templateDatas[i].productType, templateDatas: [templateDatas[i]] });
            } else {
                let grpIdx = tmpGrpTemplateDatas.findIndex(x => x.productType === templateDatas[i].productType);
                tmpGrpTemplateDatas[grpIdx].templateDatas.push(templateDatas[i]);
            }
        }

        let grpTemplateDatas = [];
        for (let i = 0; i < tmpGrpTemplateDatas.length; i++) {
            // at least one (tutti gli at least one di uno step finiscono nello stesso gruppo)
            let templateDatasAtLeastOne = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.AtLeastOne);
            if (templateDatasAtLeastOne.length > 0) grpTemplateDatas.push(templateDatasAtLeastOne);

            // mandatory (per ogni optional genero un gruppo)
            let templateDatasMandatory = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.Mandatory);
            if (templateDatasMandatory.length > 0) {
                for (let j = 0; j < templateDatasMandatory.length; j++) {
                    grpTemplateDatas.push([templateDatasMandatory[j]]);
                }
            }

            // optional (per ogni optional genero un gruppo)
            let templateDatasOptionals = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.Optional);

            if (templateDatasOptionals.length > 0) {

                // raggruppo le fakeAlternative dei prodotti opzionali di tipo Activity
                let templateDatasAtOptionalWithAlternative = [];
                for (let k = 0; k < templateDatasOptionals.length; k++) {
                    if (templateDatasAtOptionalWithAlternative.length === 0 || templateDatasAtOptionalWithAlternative.findIndex(x => x.codiceProdotto === templateDatasOptionals[k].codiceProdotto) < 0) {
                        templateDatasAtOptionalWithAlternative.push({ codiceProdotto: templateDatasOptionals[k].codiceProdotto, templateDatas: [templateDatasOptionals[k]] });
                    } else {
                        let grpIdx = templateDatasAtOptionalWithAlternative.findIndex(x => x.codiceProdotto === templateDatas[k].codiceProdotto);
                        templateDatasAtOptionalWithAlternative[grpIdx].templateDatas.push(templateDatasOptionals[k]);
                    }
                }

                for (let j = 0; j < templateDatasAtOptionalWithAlternative.length; j++) {
                    try {
                        // Ordina il templateDatas[j] in base al valore minimo in daysRange
                        templateDatasAtOptionalWithAlternative[j].templateDatas.sort((a, b) => {
                            let minA = 0;
                            if (a.daysRange && a.daysRange.length > 0) {
                                minA = Math.min(...a.daysRange);  // Trova il valore minimo in daysRange di a
                            }

                            let minB = 0;
                            if (b.daysRange && b.daysRange.length > 0) {
                                minB = Math.min(...b.daysRange);  // Trova il valore minimo in daysRange di b
                            }

                            return minA - minB; // Confronta i valori minimi per ordinare
                        });

                        // Aggiungi templateDatas ordinato all'array grpTemplateDatas
                        grpTemplateDatas.push(templateDatasAtOptionalWithAlternative[j].templateDatas);
                    } catch (ex) {
                        console.error("Errore durante l'ordinamento:", ex);
                        // In caso di errore, aggiungi comunque il templateDatas non ordinato
                        grpTemplateDatas.push(templateDatasAtOptionalWithAlternative[j].templateDatas);
                    }
                }

                //for (let j = 0; j < templateDatasOptionals.length; j++) {
                //    grpTemplateDatas.push([templateDatasOptionals[j]]);
                //}
            }
        }

        return (
            <>
                {grpTemplateDatas.map((grpTemplateData, index) =>
                    <React.Fragment key={index}>
                        <TemplateStepProduct template={template} products={grpTemplateData} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} searchParameters={searchParameters} />
                    </React.Fragment>
                )}
            </>
        );
    }

    function isShowStep(myStep) {

        if (myStep.templateData && myStep.templateData.length > 0) {
            return myStep.templateData.some(t => !t.excludeProduct);
        }

        return true;
    }

    return (
        <>
            {steps && steps.length > 0 && templateDatas && templateDatas.length > 0 &&
                <>
                    <div className="row">
                        {steps.filter(s => isShowStep(s)).map((step, key) =>
                            <div key={key} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="card p-0 mt-4 bb-0 bg-custom">
                                    {/*Testata Step*/}
                                    <div className="w-100 pl-2 h5">
                                        <span className="h4 text-white float-start"> <span className="text-white h5">{step.step}<data m3lab="Template.step"> {t(`Template:JourneyLeg`)} </data> - </span><var>{step.webDestinationName}</var></span>
                                        <span className="h5 text-white float-end my- pt-1 pr-2"><data m3lab="Template.StartStep">Dal:</data> <var>{getStepStartDate(step.step)}</var> <data m3lab="Template.EndStep">al</data> <var>{getStepEndDate(step.step)}</var></span>
                                    </div>
                                    <div className="bg-white">
                                        {
                                            step.thumbUrl && <div className="w-100">
                                                <div style={{ backgroundImage: "url(" + encodeURI(step.thumbUrl) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "220px" }}>
                                                </div>
                                            </div>
                                        }

                                        {/*Template Datas*/}
                                        {renderTemplateData(step.templateData)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            }
        </>
    );
}
