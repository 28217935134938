import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { Loading } from '../Common/Loading';
import { CartAddictionalProducts } from './CartAddictionalProducts';
import { CartPaxes } from './CartPaxes';
import { CartSummary } from './CartSummary';
import { CartBuoniSconto } from './BuoniSconto/CartBuoniSconto';
import { CartScadenziario } from './CartScadenziario';
import { CartRegistration } from './CartRegistration';
import { CartBillingData } from './CartBillingData';
import { CartPayment } from './CartPayment';
import { CartTermsConditions } from './CartTermsConditions';
import { CartStructure } from './Products/CartStructure';
import { CartActivity } from './Products/CartActivity';
import { CartCarRental } from './Products/CartCarRental';
import { CartFlight } from './Products/CartFlight';
import { CartTransfer } from './Products/CartTransfer';
import { CartTrain } from './Products/CartTrain';
import { CartCruise } from './Products/CartCruise';
import { CartTour } from './Products/CartTour';
import { ChangePolicyPrice } from './Modal/ChangePolicyPrice';
import { ConfirmDeleteCartItemModal } from './Modal/ConfirmDeleteCartItemModal';
import { CartBookParameterError } from './components/CartBookParameterError';
import { M3Icon } from '../Common/M3Icon';
import { ProductNavBar } from '../Product/SearchEngine/ProductNavBar';
import { BeginTransactionJointlyPay } from "../PaymentGateway/Jointly/service/JointlyService";
import { BookParameterId, staticBookParams, staticMandatoryBookParams } from '../../js/Constant';
import { getCurrentUserFromJwtToken, scrollTo, formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";

export const Cart = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const isCartQuotation = false;
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));

    // gestione utente anonimo
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [anonymousRegistration, setAnonymousRegistration] = useState(null);
    function checkAnonymous() {
        let isAnonymous = false;

        try {
            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            if (jCurrentUser && jCurrentUser.isanonymous)
                isAnonymous = true;

        } catch (ex) {

        }

        setIsAnonymous(isAnonymous);
    }

    function callSetValidForm(inputData) {
        setAnonymousRegistration(inputData);
    }

    function canBookAnonymous() {
        if (!isAnonymous)
            return true;

        if (anonymousRegistration)
            return true;
        return false;
    }

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    // static mandatory book params (may be updated after terms)
    const [mandatoryBookParams, setMandatoryBookParams] = useState(staticMandatoryBookParams);

    const [isLoading, setIsLoading] = useState(true);
    const [cartInitData, setCartInitData] = useState([]);
    const [addictionalProducts, setAddictionalProducts] = useState({});
    const [isCommissionable, setIsCommissionable] = useState(false);
    const [cartPaxesRequired, setCartPaxesRequired] = useState(false);
    const [cartPaxesNames, setCartPaxesNames] = useState([]);

    const [callTerms, setCallTerms] = useState(true);
    const [callTermsFinished, setCallTermsFinished] = useState(false);
    const [callTermsErrorMsg, setCallTermsErrorMsg] = useState(null);
    const [productBookParams, setProductBookParams] = useState([]);
    const [productReservationData, setProductReservationData] = useState([]);
    const [productChangePolicyPrice, setProductChangePolicyPrice] = useState([]);

    // net prices
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [showNetPrices, setShowNetPrices] = useState(false);

    // addictional products shared properties
    const [selectedAddProdIds, setSelectedAddProdIds] = useState([]);
    const [addedMkpFee, setAddedMkpFee] = useState(null);
    const [modifiedMkpFee, setModifiedMkpFee] = useState(0);

    // pax book params shared properties
    const [paxesBookParams, setPaxesBookParams] = useState([]);

    // billing data shared properties
    const [billingData, setBillingData] = useState([]);

    // terms and conditions shared properties
    const [termsConditionsData, setTermsConditionsData] = useState([]);
    const [termsConditionsMandatoryChecked, setTermsConditionsMandatoryChecked] = useState(false);

    // payment properties
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [haveAllProductPaymentMap, setHaveAllProductPaymentMap] = useState(true);
    const [forceRefreshCartPayment, setForceRefreshCartPayment] = useState(1);
    const [totalPriceToPay, setTotalPriceToPay] = useState(null);

    // add to order properties
    const [addToOrderNumber, setAddToOrderNumber] = useState(null);
    const [precompiledOrderPaxesBookParams, setPrecompiledOrderPaxesBookParams] = useState([]);

    // discount properties
    const [selectedBuoniScontoIds, setSelectedBuoniScontoIds] = useState(null);

    // others
    const [showCost, setShowCost] = useState(false);
    const [showAlertChangeCancelPolicy, setShowAlertChangeCancelPolicy] = useState(true);
    const [userLockBook, setUserLockBook] = useState(false);
    const [cartSaveToBooking, setCartSaveToBooking] = useState(false);
    const [userPermissions, setuserPermissions] = useState({ cartOnlyOption: false, canBook: true, compositionAllowed: true, messaggeError: '' });
    const [invalidBookParameters, setInvalidBookParameters] = useState(null);
    const [welfareCreditNotAvailable, setWelfareCreditNotAvailable] = useState(false);
    const [deleteCartItemId, setDeleteCartItemId] = useState(false);


    useEffect(() => {
        const getCartInitData = async () => {
            const response = callGetCartInitData();
        }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

        if (currentUser.roles) {
            let upduserPermissions = { ...userPermissions }

            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) {
                setEnableShowNetPrices(true);
            }

            if (currentUser.roles.includes("FE_NoBook")) {
                setUserLockBook(true);
            }

            if (currentUser.roles.includes("Cart_NoAlertChangeCancelPolicy")) {
                setShowAlertChangeCancelPolicy(false);
            }

            if (currentUser.roles.includes("FE_ShowCost")) {
                setShowCost(true);
            }

            if (currentUser.roles.includes("FE_NoBook") && currentUser.roles.includes("W_CartSaveToBooking")) {
                setCartSaveToBooking(true);
                upduserPermissions.saveToBooking = true;
            }

            if (currentUser.roles.includes("W_OnlyOption")) {
                upduserPermissions.cartOnlyOption = true;
            }

            setuserPermissions(upduserPermissions);
        }

        setIsLoading(true);
        getCartInitData();
        checkAnonymous();
    }, []);

    // executed after first useEffect (when terms is done)
    useEffect(() => {
        const getBookingTerms = async () => {
            const response = callGetBookingTerms();
        }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        if (currentUser.roles && currentUser.roles.includes("FE_NoBook")) {
            setCallTermsFinished(true);
            setCallTerms(false);
            return;
        }

        if (!callTerms)
            return;

        setCallTerms(false);

        if (cartInitData === undefined || cartInitData.cartSteps === undefined)
            getBookingTerms();

    }, [cartInitData]);

    // executed after second useEffect (when callTermsFinished change value)
    useEffect(() => {
        if (!callTermsFinished)
            return;

        if (precompiledOrderPaxesBookParams && precompiledOrderPaxesBookParams.length > 0) {
            // se sono nel caso AddToOrder/PaxesFromQuotation, prepopolo le dropdown di selezione dei pax simulando un cambio valore per far scatenare l'evento di update
            let bpFirstName = precompiledOrderPaxesBookParams[0].BookParameters.find(x => x.Id === BookParameterId.Personal.FirstName);
            if (bpFirstName) {
                handlePaxesBookParams({ PaxIndex: precompiledOrderPaxesBookParams[0].PaxIndex, PaxType: precompiledOrderPaxesBookParams[0].PaxType, BpId: bpFirstName.Id, BpValue: bpFirstName.Value });
            }
        }

        if (showAlertChangeCancelPolicy && productChangePolicyPrice && productChangePolicyPrice.length > 0) {
            let myBtn = document.getElementById("open_ChangePolicyPrice");
            myBtn.click();
        }
    }, [callTermsFinished]);


    async function callGetCartInitData() {
        const qId = searchParams.get("qId");

        const inputData = { IsCartQuotation: isCartQuotation, QuotationId: qId };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartInitData', requestOption);
        const response = await fetchedRes.json();

        if (response.length === 0) {
            setIsLoading(false);
            return;
        }

        if (response.addToOrderNumber) {
            setAddToOrderNumber(response.addToOrderNumber);
        }

        setCartInitData(response);
        setAddictionalProducts(response.addictionalProducts);
        setAddedMkpFee(response.addedMkpFee);
        initPaxBookParams(response);
        initBillingData(response);
        setIsCommissionable(response.isCommissionable);

        const jTrippiAddProducts = localStorage.getItem("TrippieAddictionalProducts");
        if (jTrippiAddProducts !== null && jTrippiAddProducts !== undefined) {
            let currentAddProdSelected = JSON.parse(jTrippiAddProducts);
            setSelectedAddProdIds(currentAddProdSelected);

            localStorage.removeItem("TrippieAddictionalProducts");
        }

        if (!response.canBook.allowed) {
            let upduserPermissions = { ...userPermissions };

            upduserPermissions.canBook = false;
            upduserPermissions.messaggeError = response.canBook.label;
            setuserPermissions(upduserPermissions);

            setUserLockBook(true);
        }

        setIsLoading(false);
    }

    async function callGetBookingTerms() {
        const inputData = { OldWebsiteUrl: configData.Settings.OldWebSite_BaseUrl, CultureName: cultureName };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetBookingTerms', requestOption);
        const response = await fetchedRes.json();

        if (!response.success || response.items.length === 0) {
            if (response.errorCode === "Cart.Composition.NotAllowed") {
                setCallTermsErrorMsg(t('Cart:CartErrorComposition'));
                setCallTermsFinished(true);

                let upduserPermissions = { ...userPermissions };
                upduserPermissions.compositionAllowed = false;
                setuserPermissions(upduserPermissions);

                return;
            }

            if (response.items.length === 0) {
                setCallTermsErrorMsg(t('Cart:CartErrorProduct'));
                setCallTermsFinished(true);
                return;
            }
        }

        let tmp = [];
        let tmpReservationData = [];

        for (let i = 0; i < response.items.length; i++) {
            // products book params and others data
            let prodBp = {
                CartItemId: response.items[i].cartItemId,
                IsAvailable: response.items[i].isAvailable,
                TermsError_ListUrl: response.items[i].termsError_ListUrl,
                TermsError_DetailUrl: response.items[i].termsError_DetailUrl,
                QuotationId: response.items[i].quotationId,
                QuotationItemId: response.items[i].quotationItemId,
                ProductBookParameters: response.items[i].bookParameters,
                OptionBookParameters: response.items[i].options
            };

            tmp.push(prodBp);

            // product reservation data
            let prodResData = {
                CartItemId: response.items[i].cartItemId,
                SelectedAction: null
            };

            tmpReservationData.push(prodResData);
        }

        if (response.changePolicyPrice && response.changePolicyPrice.length > 0) {
            setProductChangePolicyPrice(response.changePolicyPrice);
        }

        updateMandatoryBookParams(tmp);
        setProductBookParams(tmp);
        setProductReservationData(tmpReservationData);
        setCallTermsFinished(true);
    }

    async function callUpdateCitizenship(citizenshipCode) {
        const inputData = { CitizenshipCode: citizenshipCode, CultureName: cultureName };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateCitizenship', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success && response.callBookingTerms) {
                setCallTermsErrorMsg(null);
                setCallTermsFinished(false);
                callGetBookingTerms();
            }
        }
    }

    // aggiorna i book parameters obbligatori dopo aver chiamato la Terms
    const updateMandatoryBookParams = (productsBookParams) => {
        let tmp = [];
        for (var i = 0; i < mandatoryBookParams.length; i++) { // copy array
            tmp.push(mandatoryBookParams[i]);
        }

        for (let i = 0; i < productsBookParams.length; i++) { // product
            if (!productsBookParams[i].IsAvailable) {
                continue;
            }

            let globalPaxIndex = 0;

            if (productsBookParams[i].OptionBookParameters)
                for (let j = 0; j < productsBookParams[i].OptionBookParameters.length; j++) { // room/option
                    for (let k = 0; k < productsBookParams[i].OptionBookParameters[j].paxes.length; k++) { // pax
                        globalPaxIndex++;
                        let pax = productsBookParams[i].OptionBookParameters[j].paxes[k];

                        for (let bi = 0; bi < pax.bookParameters.length; bi++) { // pax book parameters
                            let bookParam = pax.bookParameters[bi];
                            if (!bookParam.mandatory) // se non è mandatory esco
                                continue;

                            // se non appartiene agli static esco (in quanto il mandatory sarà gestito per ogni singolo componenete/prodotto)
                            let staticBpIndex = staticBookParams.findIndex(x => x.Id === bookParam.id);
                            if (staticBpIndex < 0)
                                continue;

                            let objBpIndex = tmp.findIndex(x => x.Id === bookParam.id);
                            if (objBpIndex < 0) {
                                tmp.push({ Id: bookParam.id, PaxIndexes: [globalPaxIndex] });
                            } else {
                                if (!tmp[objBpIndex].PaxIndexes.includes(globalPaxIndex) && !tmp[objBpIndex].OnlyFirstPax)
                                    tmp[objBpIndex].PaxIndexes.push(globalPaxIndex);
                            }
                        }
                    }
                }
        }

        setMandatoryBookParams(tmp);
    }

    // restituisce i book parameters di un determinato cart item
    const getCartItemBookParams = (cartItemId) => {
        let myIndex = productBookParams.findIndex(x => x.CartItemId === cartItemId);
        return productBookParams[myIndex];
    }


    // inizializza i book parameters dei pax che hanno già un valore di default settato (es: gender o default country)
    const initPaxBookParams = (response) => {
        let cartPaxes = response.cartPaxes;
        let tmpPaxesBookParams = [];

        for (let i = 0; i < cartPaxes.length; i++) {
            let cartPax = cartPaxes[i];

            let bookParameters = [];

            if ((response.addToOrderNumber || response.paxesFromQuotation) && cartPax.bookParameters) {
                // se arrivo da un add to order ho gia i dati dei pax
                for (let j = 0; j < cartPax.bookParameters.length; j++) {
                    bookParameters.push({ Id: cartPax.bookParameters[j].id, Value: cartPax.bookParameters[j].value });
                }
            } else {
                // altrimenti metto i default
                bookParameters.push({ Id: BookParameterId.Personal.Gender, Value: response.defaultValues.gender });
                bookParameters.push({ Id: BookParameterId.Personal.Citizenship, Value: response.defaultValues.country });

                if (i === 0) { // only refPax
                    bookParameters.push({ Id: `${BookParameterId.Contact.HomePhone}-Prefix`, Value: response.defaultValues.dialCode });
                }
            }

            tmpPaxesBookParams.push({ PaxIndex: cartPax.index, PaxType: cartPax.paxType, BookParameters: bookParameters });
        }

        if (response.addToOrderNumber || response.paxesFromQuotation) {
            // --- clone array to make it indipendent ---
            let aPrecompiled = [];
            for (let i = 0; i < tmpPaxesBookParams.length; i++) {
                let tmpObj = { PaxIndex: tmpPaxesBookParams[i].PaxIndex, PaxType: tmpPaxesBookParams[i].PaxIndex, BookParameters: [] };
                for (let j = 0; j < tmpPaxesBookParams[i].BookParameters.length; j++) {
                    tmpObj.BookParameters.push({ Id: tmpPaxesBookParams[i].BookParameters[j].Id, Value: tmpPaxesBookParams[i].BookParameters[j].Value });
                }
                aPrecompiled.push(tmpObj);
            }

            setPrecompiledOrderPaxesBookParams(aPrecompiled);
            // --- clone array to make it indipendent ---

            checkCartPaxesRequired(tmpPaxesBookParams);
        }

        setPaxesBookParams(tmpPaxesBookParams);
    }

    // inizializza i billing data che hanno già un valore di default settato (es: gender o default prefix)
    const initBillingData = (response) => {
        let tmpBillingData = [];
        tmpBillingData.push({ Id: BookParameterId.Personal.Gender, Value: response.defaultValues.gender });
        tmpBillingData.push({ Id: `${BookParameterId.Contact.HomePhone}-Prefix`, Value: response.defaultValues.dialCode });

        setBillingData(tmpBillingData);
    }

    // controlla se sono stati settati tutti i book parameters obbligatori
    const checkCartPaxesRequired = (paxesBookParams) => {
        let isCompleted = true;

        for (let i = 0; i < mandatoryBookParams.length; i++) {
            let mandatoryBp = mandatoryBookParams[i]; // ogni pax deve avere questo book parameter popolato

            for (let j = 0; j < paxesBookParams.length; j++) {
                // se non è per il pax corrente, skippo
                if (mandatoryBp.PaxIndexes.length !== 0 && !mandatoryBp.PaxIndexes.includes(j + 1))
                    continue;

                if (paxesBookParams[j].BookParameters.filter(e => e.Id === mandatoryBp.Id).length === 0) {
                    isCompleted = false;
                    break;
                }
            }
        }

        setCartPaxesRequired(isCompleted);

        // set cart pax name to pass to products components
        if (isCompleted) {
            let tmpCartPaxesNames = [];
            for (let i = 0; i < paxesBookParams.length; i++) {
                let index = paxesBookParams[i].PaxIndex;
                let bpFirstName = paxesBookParams[i].BookParameters.filter(e => e.Id === BookParameterId.Personal.FirstName);
                let bpLastName = paxesBookParams[i].BookParameters.filter(e => e.Id === BookParameterId.Personal.LastName);

                if (bpFirstName.length > 0 && bpLastName.length > 0)
                    tmpCartPaxesNames.push({ Index: index, BpFirstName: bpFirstName, BpLastName: bpLastName });
            }

            setCartPaxesNames(tmpCartPaxesNames);
        }
    }

    // gestisce i book parameters dei pax man mano che vengono compilati (richiamato dal componente CartPaxes.js)
    const handlePaxesBookParams = (bpPax) => {
        let tmpPaxesBookParams = [];
        for (var i = 0; i < paxesBookParams.length; i++) { // copy array
            tmpPaxesBookParams.push(paxesBookParams[i]);
        }

        // get or add pax
        let bpPaxIndex = Number(bpPax.PaxIndex);
        let objPaxIndex = tmpPaxesBookParams.findIndex(x => x.PaxIndex === bpPaxIndex);
        if (objPaxIndex < 0) {
            tmpPaxesBookParams.push({ PaxIndex: bpPaxIndex, PaxType: bpPax.PaxType, BookParameters: [] });
            objPaxIndex = tmpPaxesBookParams.findIndex(x => x.PaxIndex === bpPaxIndex);
        }

        // add or update book parameter for pax
        let objBpIndex = tmpPaxesBookParams[objPaxIndex].BookParameters.findIndex(x => x.Id === bpPax.BpId);
        if (objBpIndex < 0) {
            tmpPaxesBookParams[objPaxIndex].BookParameters.push({ Id: bpPax.BpId, Value: bpPax.BpValue });
        } else {
            if (bpPax.BpValue !== "" && bpPax.BpValue !== null && bpPax.BpValue !== undefined) {
                tmpPaxesBookParams[objPaxIndex].BookParameters[objBpIndex].Value = bpPax.BpValue;
            } else {
                tmpPaxesBookParams[objPaxIndex].BookParameters.splice(objBpIndex, 1);
            }
        }

        checkCartPaxesRequired(tmpPaxesBookParams);
        setPaxesBookParams(tmpPaxesBookParams);

        // if is RefPax and changed citizenship, check and call again (if needed) the booking terms
        if (bpPax.PaxIndex === "1" && bpPax.BpId === BookParameterId.Personal.Citizenship) {
            callUpdateCitizenship(bpPax.BpValue);
        }
    }

    // gestisce i dati di fatturazione man mano che vengono compilati (richiamato dal componente CartBillingData.js)
    const handleBillingData = (inBillingData) => {
        let tmpBillingData = [];
        for (var i = 0; i < billingData.length; i++) { // copy array
            tmpBillingData.push(billingData[i]);
        }

        let objIndex = tmpBillingData.findIndex(x => x.Id === inBillingData.Id);
        if (objIndex < 0) {
            tmpBillingData.push({ Id: inBillingData.Id, Value: inBillingData.Value, Mandatory: inBillingData.Mandatory });
        } else {
            tmpBillingData[objIndex].Value = inBillingData.Value;
            tmpBillingData[objIndex].Mandatory = inBillingData.Mandatory;
        }

        setBillingData(tmpBillingData);
    }



    // gestisce l'aggiunta di un prodotto aggiuntivo (assicurazioni, etc.) tra quelli selezionati
    const handleAddProdSelection = (aProducts) => {
        let tmpSelected = [];

        // copy array
        for (var i = 0; i < selectedAddProdIds.length; i++) {
            tmpSelected.push(selectedAddProdIds[i]);
        }

        for (let i = 0; i < aProducts.length; i++) {
            let objIndex = tmpSelected.findIndex(x => x.idProdotto === aProducts[i].idProdotto);

            if (objIndex < 0)
                tmpSelected.push(aProducts[i]);
            else
                tmpSelected[objIndex] = aProducts[i];
        }

        setSelectedAddProdIds(tmpSelected);
    }


    // gestisce l'aggiunta/aggiornamento dei book parameters di un prodotto
    const handleBookParameters = (cartItemId, type, data) => {
        let reloadTotalAmount = false;

        let tmpProductBookParams = [];
        for (let i = 0; i < productBookParams.length; i++) { // copy array
            tmpProductBookParams.push(productBookParams[i]);
        }

        for (let i = 0; i < tmpProductBookParams.length; i++) { // per ogni prodotto
            if (tmpProductBookParams[i].CartItemId !== cartItemId)
                continue;

            switch (type) {
                case "PRODUCT":
                    let myProductBookParams = tmpProductBookParams[i].ProductBookParameters;
                    for (let bp = 0; bp < data.BookParameters.length; bp++) { // per ogni book parameter in arrivo
                        let bpIdx = myProductBookParams.findIndex(x => x.id === data.BookParameters[bp].Id);
                        if (bpIdx < 0) {
                            myProductBookParams.push(data.BookParameters[bp]);
                            reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(null, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                        } else {
                            let oldValue = myProductBookParams[bpIdx].value;
                            myProductBookParams[bpIdx].value = data.BookParameters[bp].Value;
                            reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(oldValue, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                        }
                    }
                    break;

                case "OPTION":
                    for (let j = 0; j < tmpProductBookParams[i].OptionBookParameters.length; j++) { // per ogni room/option
                        let myOption = tmpProductBookParams[i].OptionBookParameters[j];
                        if (data.Sequence !== myOption.sequence) {
                            continue;
                        }

                        for (let bp = 0; bp < data.BookParameters.length; bp++) { // per ogni book parameter in arrivo
                            let bpIdx = myOption.bookParameters.findIndex(x => x.id === data.BookParameters[bp].Id);
                            if (bpIdx < 0) {
                                myOption.bookParameters.push(data.BookParameters[bp]);
                                reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(null, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                            } else {
                                let oldValue = myOption.bookParameters[bpIdx].value;
                                myOption.bookParameters[bpIdx].value = data.BookParameters[bp].Value;
                                reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(oldValue, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                            }
                        }
                    }
                    break;

                case "PAX":
                    if (!tmpProductBookParams[i].OptionBookParameters)
                        continue;

                    for (let j = 0; j < tmpProductBookParams[i].OptionBookParameters.length; j++) { // per ogni room/option
                        let myOption = tmpProductBookParams[i].OptionBookParameters[j];
                        if (data.Sequence !== myOption.sequence) {
                            continue;
                        }

                        for (let k = 0; k < tmpProductBookParams[i].OptionBookParameters[j].paxes.length; k++) { // per ogni pax
                            let myPax = tmpProductBookParams[i].OptionBookParameters[j].paxes[k];
                            if (Number(data.PaxIndex) !== myPax.paxIndex) {
                                continue;
                            }

                            for (let bp = 0; bp < data.BookParameters.length; bp++) { // per ogni book parameter in arrivo
                                let bpIdx = myPax.bookParameters.findIndex(x => x.id === data.BookParameters[bp].Id);
                                if (bpIdx < 0) {
                                    myPax.bookParameters.push(data.BookParameters[bp]);
                                    reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(null, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                                } else {
                                    let oldValue = myPax.bookParameters[bpIdx].value;
                                    myPax.bookParameters[bpIdx].value = data.BookParameters[bp].Value;
                                    reloadTotalAmount = reloadTotalAmount || isBookParameterChangeAmount(oldValue, data.BookParameters[bp].Value, data.BookParameters[bp].Amount);
                                }
                            }
                        }
                    }
                    break;

                default:
                    break;
            }
        }

        setProductBookParams(tmpProductBookParams);

        // chiamata per aggiornare il prezzo in caso di book parameter con amount
        if (reloadTotalAmount)
            updateProductBookParameters();

        // in caso stia aggiornando solo il book parameter "RES-PRT" dei voli, devo forzare l'aggiornamento dei metodi di pagamento
        if (data && data.BookParameters && data.BookParameters.length > 0) {
            if (data.BookParameters[0].Id === "RES-PRT") {
                let updForceRefreshCartPayment = forceRefreshCartPayment + 1;
                setForceRefreshCartPayment(updForceRefreshCartPayment);
            }
        }
    }

    function isBookParameterChangeAmount(oldBPValue, newBPValue, newBPAmount) {
        let res = false;

        if (newBPAmount && newBPAmount > 0) {
            if (!oldBPValue || oldBPValue !== newBPValue)
                res = true;
        }

        return res;
    }

    async function updateProductBookParameters() {
        try {
            let inputData =
            {
                WebsiteBaseUrl: `${configData.Settings.OldWebSite_BaseUrl}${configData.Settings.Base_Url}`,
                PaxesBookParameters: [],
                ProductBookParameters: productBookParams,
                AddictionalProductSelected: selectedAddProdIds,
                BillingData: [],
                AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: "Assicurazione, quote di partecipazione e altri costi" },
                ReservationInfoData: productReservationData,
                TermsConditionsData: termsConditionsData,
                SelectedPaymentType: selectedPaymentType,
                AnonymousRegistration: anonymousRegistration
            };

            const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateProductBookParameters', requestOption);
            if (fetchedRes.ok) {
                const response = await fetchedRes.json();
                if (!response || !response.success) {
                    console.log("UpdateProductBookParameters false");
                }
            }
        }
        catch (ex) {
            console.log("Error UpdateProductBookParameters: " + ex);
        }

        // trucco per far ricaricare lo scadenziario e la parte dei pagamenti
        let tmpSelectedAddProdIds = [];
        for (var i = 0; i < selectedAddProdIds.length; i++) {
            tmpSelectedAddProdIds.push(selectedAddProdIds[i]);
        }
        setSelectedAddProdIds(tmpSelectedAddProdIds);
    }

    // gestisce l'aggiunta/aggiornamento dei reservation data di un prodotto
    const handleReservationData = (cartItemId, type, data) => {
        let tmpProductReservationData = [];
        for (let i = 0; i < productReservationData.length; i++) { // copy array
            tmpProductReservationData.push(productReservationData[i]);
        }

        for (let i = 0; i < tmpProductReservationData.length; i++) {
            if (tmpProductReservationData[i].CartItemId !== cartItemId)
                continue;

            switch (type) {
                case "ProductAction":
                    tmpProductReservationData[i].SelectedAction = data;
                    break;

                default:
                    break;
            }
        }

        setProductReservationData(tmpProductReservationData);
    }

    // gestisce il check sui termini e condizioni
    const handleTermsAndConditions = (selectedNotes) => {
        setTermsConditionsData(selectedNotes);
    }

    const callIsCheckedAllTermsAndConditions = (isAllChecked) => {
        setTermsConditionsMandatoryChecked(isAllChecked);
    }

    // gestisce la selezione del tipo di pagamento
    const handleSelectedPaymentType = (sPaymentType) => {
        setSelectedPaymentType(sPaymentType);
    }

    // gestisce il fatto di avere settatto tutti i metodi di pagamento
    const handleHaveAllProductPaymentMap = (sHaveAllProductPaymentMap) => {
        setHaveAllProductPaymentMap(sHaveAllProductPaymentMap);

        if (!sHaveAllProductPaymentMap)
            setUserLockBook(true);

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        if (currentUser.roles.includes("W_CartSaveToBooking")) {
            setCartSaveToBooking(true);

            let upduserPermissions = { ...userPermissions };
            upduserPermissions.saveToBooking = true;
            setuserPermissions(upduserPermissions);
        }
    }


    // controlla se tutti i book parameters "mandatory" di tutti i prodotti sono valorizzati
    const checkAllMandatoryProductBookParams = () => {
        for (let i = 0; i < productBookParams.length; i++) {
            let product = productBookParams[i];

            // product book params
            for (let j = 0; j < product.ProductBookParameters.length; j++) {
                if (product.ProductBookParameters[j].mandatory && !product.ProductBookParameters[j].hidden && (product.ProductBookParameters[j].value === null || product.ProductBookParameters[j].value === undefined || product.ProductBookParameters[j].value === "")) {
                    return false;
                }
            }

            // options
            for (let j = 0; j < product.OptionBookParameters.length; j++) {
                let option = product.OptionBookParameters[j];

                // option book param
                if (!option.bookParameters)
                    continue;

                for (let k = 0; k < option.bookParameters.length; k++) {
                    if (option.bookParameters[k].mandatory && !option.bookParameters[k].hidden && (option.bookParameters[k].value === null || option.bookParameters[k].value === undefined || option.bookParameters[k].value === "")) {
                        return false;
                    }
                }
            }
        }

        // se la validazione è OK, restituisco il valore di "cartPaxesRequired" per verificare di aver selezionato tutti i pax
        return cartPaxesRequired;
    }

    // controlla se tutti i billing data "mandatory" sono valorizzati
    const checkBillingData = () => {
        for (let i = 0; i < billingData.length; i++) {
            if (billingData[i].Mandatory && (!billingData[i].Value || billingData[i].Value === ""))
                return false;
        }

        return true;
    }

    // controlla se tutti i flag "mandatory" sono checked
    const checkTermsAndConditions = () => {
        for (let i = 0; i < termsConditionsData.length; i++) {
            if (termsConditionsData[i].CheckType === 3 && !termsConditionsData[i].Selected)
                return false;
        }

        return true;
    }

    // controlla se tutti i pax di una prenotazioni sono diversi tra loro
    const checkSamePaxes = () => {
        try {
            // recupero tutte le drop down dei paxes
            let ddlPaxes = document.querySelectorAll("[id*='ddlPax']");

            // tiro fuori tutti i cartItemId per poter raggruppare dopo
            let ciIds = [];
            for (let i = 0; i < ddlPaxes.length; i++) {
                let cartItemId = ddlPaxes[i].id.split("_")[0] + "_" + ddlPaxes[i].id.split("_")[1];
                if (!ciIds.includes(cartItemId))
                    ciIds.push(cartItemId);
            }

            // controllo se per ogni gruppo 
            for (let i = 0; i < ciIds.length; i++) {
                // raggruppo le dropdown sul cartItemId (quindi le raggruppo per prenotazione)
                let grpDdl = document.querySelectorAll("[id*='ddlPax'][id*='" + ciIds[i] + "']");

                // tiro fuori i values che sono gli indici dei pax inseriti
                let selectedValues = [];
                for (let i = 0; i < grpDdl.length; i++) {
                    selectedValues.push(grpDdl[i].value);
                }

                // conto quante volte ogni index appare nell'array, se ne ho piu di uno vuol dire che mi hanno messo lo stesso pax piu volte
                for (let i = 0; i < selectedValues.length; i++) {
                    if (selectedValues.filter(x => x === selectedValues[i]).length > 1) {
                        return false;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }

        return true;
    }

    // evidenzia gli input fields non compilati
    const highlightInvaldFields = (e) => {
        let firstInvalidFieldId = "";

        // required fields
        let requiredFields = document.querySelectorAll("[required]");
        for (let i = 0; i < requiredFields.length; i++) {
            let inputField = requiredFields[i];
            if (inputField.value === "" || (inputField.tagName.toUpperCase() === 'SELECT' && inputField.value === "-1")) {
                inputField.classList.add("is-invalid");

                if (firstInvalidFieldId === "") {
                    firstInvalidFieldId = inputField.id;
                }
            } else {
                inputField.classList.remove("is-invalid");
            }
        }

        // ddl select paxes
        let selectedPaxes = document.querySelectorAll("[id*='ddlPax']");
        for (let i = 0; i < selectedPaxes.length; i++) {
            let selectField = selectedPaxes[i];
            if (selectField.value === "-1") {
                selectField.classList.add("is-invalid");

                if (firstInvalidFieldId === "") {
                    firstInvalidFieldId = selectField.id;
                }
            } else {
                selectField.classList.remove("is-invalid");
            }
        }

        // scroll to first invalid
        if (firstInvalidFieldId !== "")
            scrollTo(e, firstInvalidFieldId);
    }


    // avvia la scrittura pratica
    async function getStartBooking(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        let alertStartBookingError = document.getElementById("alertStartBookingError");
        let alertBookParametersError = document.getElementById("alertBookParametersError");
        let alertWelfareCreditCheck = document.getElementById("alertWelfareCreditCheck");
        let btnBookNow = document.getElementById("btnBookNow");
        let imgBookLoading = document.getElementById("imgBookLoading");

        try {
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/StartBooking', requestOption);
            const response = await fetchedRes.json();

            let isSuccessStartBooking = response.success;
            let isJointlySuccess = true;

            if (isSuccessStartBooking) {
                let jointlyRedirectUrl = "";
                if (response.jointlyInfo) {

                    jointlyRedirectUrl = await BeginTransactionJointlyPay(response.jointlyInfo);
                    if (!jointlyRedirectUrl)
                        isJointlySuccess = false;
                    else
                        isJointlySuccess = true;
                }

                if (isJointlySuccess) {
                    cookies.set("orderNumber", response.orderNumber, { path: "/" });

                    window.location.href = response.paymentUrl
                        ? (!jointlyRedirectUrl ? response.paymentUrl : jointlyRedirectUrl)
                        : `${configData.Settings.Base_Url}cart-confirm/${response.orderNumber}`;
                }
            }

            if (!isSuccessStartBooking || !isJointlySuccess) {
                imgBookLoading.style.display = "none";
                btnBookNow.style.display = "";

                if (response.invalidBookParameters && response.invalidBookParameters.length > 0) {
                    alertBookParametersError.style.display = "inline-block";

                    let jObj = JSON.parse(response.invalidBookParameters);
                    setInvalidBookParameters(jObj);

                    console.log("StartBooking - BookParameters Error: " + response.invalidBookParameters);
                } else if (response.invalidWelfareCredit) {
                    alertWelfareCreditCheck.style.display = "inline-block";
                    console.log("StartBooking - Welfare Credit Not Available");
                } else {
                    alertStartBookingError.style.display = "inline-block";

                    console.log("StartBooking - Failed: " + response.message);
                }
            }
        }
        catch (ex) {
            alertStartBookingError.style.display = "inline-block";
            imgBookLoading.style.display = "none";
            btnBookNow.style.display = "";

            console.log("StartBooking - Failed: " + ex);
        }
    }

    const startBooking = (e) => {
        e.preventDefault();

        // blocca flusso se l'utente ha il LockBook (il msg viene dato subito in cima al carrello)
        if (userLockBook)
            return;

        // blocca flusso se la BookingTerms non ha ancora completato
        let alertBookingTermsNotFinished = document.getElementById("alertBookingTermsNotFinished");

        if (!callTermsFinished) {
            alertBookingTermsNotFinished.style.display = "inline-block";
            return;
        } else {
            alertBookingTermsNotFinished.style.display = "none";
        }

        // reset other messages
        let alertStartBookingError = document.getElementById("alertMandatoryFields");
        alertStartBookingError.style.display = "none";

        // check isValid
        let isValid = checkAllMandatoryProductBookParams() && checkBillingData();

        let alertMandatoryFields = document.getElementById("alertMandatoryFields");
        alertMandatoryFields.style.display = !isValid ? "inline-block" : "none";

        if (!isValid) {
            highlightInvaldFields(e);
            return;
        }

        // check terms and conditions
        isValid = checkTermsAndConditions();
        document.getElementById("alertTermsConditions").style.display = !isValid ? "inline-block" : "none";
        if (!isValid) {
            return;
        }

        // check same passenger in same reservation
        isValid = checkSamePaxes(e);

        let alertSamePaxes = document.getElementById("alertSamePaxes");
        alertSamePaxes.style.display = !isValid ? "inline-block" : "none";

        if (!isValid) {
            return;
        }

        let btnBookNow = document.getElementById("btnBookNow");
        btnBookNow.style.display = "none";

        let imgBookLoading = document.getElementById("imgBookLoading");
        imgBookLoading.style.display = "";

        let filteredProductBookParams = productBookParams.filter(x => { return x.IsAvailable === true });

        let inputData =
        {
            WebsiteBaseUrl: window.location.origin,
            PaxesBookParameters: paxesBookParams,
            ProductBookParameters: filteredProductBookParams,
            AddictionalProducts: addictionalProducts,
            AddictionalProductSelected: selectedAddProdIds,
            BillingData: billingData,
            AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: "Assicurazione, quote di partecipazione e altri costi" },
            ReservationInfoData: productReservationData,
            TermsConditionsData: termsConditionsData,
            SelectedPaymentType: selectedPaymentType,
            AnonymousRegistration: anonymousRegistration,
            AddedMkpFee: addedMkpFee,
            CultureName: cultureName,
            SelectedBuoniScontoIds: selectedBuoniScontoIds
        };

        if (debug === "1") {
            console.log(JSON.stringify(inputData));
        }

        getStartBooking(inputData);
    }


    // open popup "RichiestaInterventoBooking"
    const openModalRichiestaInterventoBooking = useCallback(() => {
        let myBtn = document.getElementById("open_RichiestaInterventoBooking");
        myBtn.click();
    }, []);

    // gestisce l'aggiunta di prodotti aggiuntivi "manuali"
    const handleAddedMkpFee = (addProds) => {
        setAddedMkpFee(addProds);
    }
    const handleModifyMkpFee = () => {
        let newModifiedMkpFee = modifiedMkpFee + 1;
        setModifiedMkpFee(newModifiedMkpFee);
    }


    // discount
    const onSelectBuonoSconto = (data) => {
        let tmpSelectedBuoniScontoIds = [];

        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let toAdd = false;
                if (selectedBuoniScontoIds && selectedBuoniScontoIds.length > 0) {
                    let actualBuonoScontoId = selectedBuoniScontoIds.filter(x => x === data[i].buonoScontoId);
                    if (!actualBuonoScontoId) {
                        toAdd = true;
                    }
                } else {
                    toAdd = true;
                }

                if (toAdd) {
                    tmpSelectedBuoniScontoIds.push(data[i].buonoScontoId);
                }
            }
        }

        setSelectedBuoniScontoIds(tmpSelectedBuoniScontoIds);
    }

    // total price to pay
    const onUpdateTotalPriceToPay = (totalPrice) => {
        setTotalPriceToPay(totalPrice);

        // check welfare credit
        if (cartInitData && cartInitData.welfareInfoCredit) {
            if (totalPrice > cartInitData.welfareInfoCredit.displayPrice) {
                setWelfareCreditNotAvailable(true);
            } else {
                setWelfareCreditNotAvailable(false);
            }
        }
    }

    function onOpenModalConfirmDelete(cartItemId) {
        try {
            let btn = document.getElementById("btnOpenModalDeleteCartItemId");
            btn.click();

            setDeleteCartItemId(cartItemId);
        } catch (ex) {

        }
    }

    return (
        <>
            {configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar />}

            {isLoading
                ? (<div className="mt-4 bg-white rounded py-2"><Loading textMsg={t(`MyBook:Loading`)} /></div>)
                : cartInitData === undefined || cartInitData.cartSteps === undefined || cartInitData.cartPaxes === undefined
                    ? (
                        <div className="container basket">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                    <div className="card mt-4 mb-4 p-3">
                                        <h5>{t("Cart:NoProduct")}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="container basket">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                    {/*Breadcrumb*/}
                                    <nav className="nav nav-fill mt-4 bg-white rounded">
                                        {/*<div className="nav-link h085 position-relative bg-custom" href="#">Riepilogo</div>*/}
                                        {/*<a className="nav-link " href="#"><div className="solid-line"></div></a>*/}
                                        <a className="nav-link" href="#">{t(`MyBook:Passengers`)}</a>
                                        <a className="nav-link " href="#"><div className="solid-line "></div></a>
                                        <a className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">{t(`Button:Confirm`)}</a>
                                    </nav>

                                    {/*top messages*/}
                                    {userPermissions && userPermissions.messaggeError
                                        ? (<><h6 className="text-danger text-center mt-4" dangerouslySetInnerHTML={{ __html: t(`Cart:${userPermissions.messaggeError}`) }}></h6></>)
                                        : (<></>)
                                    }

                                    {userLockBook && !cartSaveToBooking && !userPermissions.messaggeError
                                        ? (<><h6 className="text-danger text-center mt-4" dangerouslySetInnerHTML={{ __html: t(`Cart:Warning_NoBook`) }}></h6></>)
                                        : (<></>)
                                    }

                                    {userLockBook && cartSaveToBooking && !userPermissions.messaggeError
                                        ? (<><h6 className="text-danger text-center mt-4">Per completare l'acquisto è necessario inviare la <a className="lnkReqInterventoBooking" onClick={e => openModalRichiestaInterventoBooking()}>richiesta di intervento booking</a>.</h6></>)
                                        : (<></>)
                                    }

                                    {!userLockBook && callTermsErrorMsg
                                        ? (<><h6 className="text-danger text-center mt-4"><div dangerouslySetInnerHTML={{ __html: callTermsErrorMsg }}></div></h6></>)
                                        : (<></>)
                                    }

                                    {welfareCreditNotAvailable
                                        ? (<><h6 className="text-danger text-center mt-4">{t(`Cart:Alert_WelfareCreditNotAvailable`)}</h6></>)
                                        : (<></>)
                                    }

                                    {/*Passengers*/}
                                    {!userLockBook &&
                                        <div className="basket-card card mt-4 p-3">
                                            <div>
                                                <M3Icon iconName="PaxAdl" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                                <span className="titlecard">{t(`MyBook:ListPassengerCart`)}</span>

                                                {addToOrderNumber && (<span className="float-right status confirm h5">{t(`MyBook:AddToOrder`)} n. {addToOrderNumber}</span>)}
                                            </div>
                                            <div>{t(`MyBook:NamePaxTextCart`)}</div>
                                            <div>{t(`MyBook:NamePaxTextCart2`)}</div>

                                            <CartPaxes
                                                inputData={cartInitData}
                                                handlePaxesBookParams={handlePaxesBookParams}
                                                mandatoryBookParams={mandatoryBookParams}
                                                precompiledOrderPaxesBookParams={precompiledOrderPaxesBookParams}
                                                isCommissionable={isCommissionable}
                                            />

                                            {/* --- DEBUG --- */}
                                            {debug === "1" &&
                                                <>
                                                    <br />{JSON.stringify(paxesBookParams)}
                                                    <br /><br />Paxes Completed: {cartPaxesRequired ? "OK" : "KO"}
                                                    <br />{JSON.stringify(cartPaxesNames)}
                                                </>
                                            }
                                            {/* --- DEBUG --- */}
                                        </div>
                                    }

                                    {/*CartItems*/}
                                    {cartInitData.cartSteps.map((stepInfo, key) => {
                                        return <div key={key} className={(key === (cartInitData.cartSteps.length - 1) ? " mb-5 " : "")}>
                                            <div>
                                                <div className={"basket-card card bg-gray-300 p-3 " + (key === 0 ? " mt-5 " : " mt-3 ")}>
                                                    <span className="h5 m-0">
                                                        <label className="pr-2">{t("MyBook:From")}:</label>{formatDateTime(stepInfo.dateFrom, cultureName)}
                                                        <label className="pr-2 pl-2"> {t("MyBook:To")}:</label>{formatDateTime(stepInfo.dateTo, cultureName)}
                                                        {stepInfo.stepName && <span> - {stepInfo.stepName}</span>}
                                                    </span>
                                                </div>
                                            </div>
                                            {
                                                stepInfo.cartItems && stepInfo.cartItems.map((item) => {
                                                    return <React.Fragment key={item.cartItemId}>
                                                        {item.productType === configData.Settings.Products.Structure.IdTipoProdotto && <CartStructure cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Activity.IdTipoProdotto && <CartActivity cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.CarRental.IdTipoProdotto && <CartCarRental cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Transfer.IdTipoProdotto && <CartTransfer cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Flight.IdTipoProdotto && <CartFlight cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Train.IdTipoProdotto && <CartTrain cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Cruise.IdTipoProdotto && <CartCruise cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                        {item.productType === configData.Settings.Products.Tour.IdTipoProdotto && <CartTour cartItemId={item.cartItemId} isCartQuotation={isCartQuotation} userPermissions={userPermissions} cartPaxesNames={cartPaxesNames} staticBookParams={staticBookParams} productBookParams={getCartItemBookParams(item.cartItemId)} handleBookParameters={handleBookParameters} handleReservationData={handleReservationData} userLockBook={userLockBook} callTermsFinished={callTermsFinished} onOpenModalConfirmDelete={onOpenModalConfirmDelete} />}
                                                    </React.Fragment>;
                                                })
                                            }
                                        </div>
                                    }
                                    )}

                                    {/* --- DEBUG --- */}
                                    {debug === "1"
                                        ? (
                                            <div className="basket-card card mt-5 p-3" style={{ border: '1px solid red' }}>
                                                <h6 style={{ color: 'red' }}>DEBUG - ALL PRODUCTS BOOK PARAMETERS</h6>
                                                All Mandatory BookParams: {checkAllMandatoryProductBookParams() ? "OK" : "KO"}
                                                <br /><br />
                                                {JSON.stringify(productBookParams)}
                                            </div>
                                        )
                                        : (<></>)
                                    }
                                    {/* --- DEBUG --- */}

                                    {/*Addictional Products*/}
                                    <CartAddictionalProducts
                                        defaultCheckedItems={selectedAddProdIds}
                                        handleAddProdSelection={handleAddProdSelection} />

                                    {/*Buoni Sconto*/}
                                    {<CartBuoniSconto onSelectBuonoSconto={onSelectBuonoSconto} />}

                                    {/*Scadenziario*/}
                                    {<CartScadenziario selectedAddProdIds={selectedAddProdIds}
                                        addictionalProducts={addictionalProducts}
                                        addedMkpFee={addedMkpFee}
                                        modifiedMkpFee={modifiedMkpFee}
                                        selectedBuoniScontoIds={selectedBuoniScontoIds}
                                    />}

                                    {/*Registrazione EndUser*/}
                                    {!addToOrderNumber && isAnonymous && !userLockBook && (
                                        <div className="basket-card card mt-5 p-3">
                                            <CartRegistration callSetValidForm={callSetValidForm} />
                                        </div>
                                    )}

                                    {/*Billing Data*/}
                                    {!addToOrderNumber && !userLockBook && (
                                        <div className="basket-card card mt-5 p-3" style={{ display: configData.Settings.Cart.ShowBillingData ? '' : 'none' }}>
                                            <CartBillingData
                                                inputData={cartInitData}
                                                paxesBookParams={paxesBookParams}
                                                handleBillingData={handleBillingData}
                                                isCommissionable={isCommissionable}
                                            />

                                            {/* --- DEBUG --- */}
                                            {debug === "1" ? (<><br /><br />{JSON.stringify(billingData)}<br />All BillingData: {checkBillingData() ? "OK" : "KO"}</>) : (<></>)}
                                            {/* --- DEBUG --- */}
                                        </div>
                                    )}

                                    {/*Terms and Conditions*/}
                                    {userPermissions.compositionAllowed && !userLockBook && (
                                        <div className="basket-card card mt-5 p-3">
                                            <div>
                                                <h5>{t("MyBook:TermsAndCondition")}</h5>
                                            </div>

                                            {/*TEMP TRAIN DYNAMIC NOTES*/}
                                            {/*{cartInitData && cartInitData.trenitaliaStationIds && cartInitData.trenitaliaStationIds.length > 0 &&*/}
                                            {/*    cartInitData.trenitaliaStationIds.map((v, i) => {*/}
                                            {/*        let noteText = t(`TrenitaliaDynamicNotes:${v}`);*/}
                                            {/*        if (noteText && noteText != `TrenitaliaDynamicNotes:${v}`) {*/}
                                            {/*            return (*/}
                                            {/*                <>*/}
                                            {/*                    <div key={i} className="mt-1 pt-3 pb-2 text-center">*/}
                                            {/*                        <img src="https://cdn.travelplace.ch/travelplace40/img/trenitalia_contributo_accesso_venezia.png" alt="" className="pt-2 pb-4" style={{ width: '600px' }} />*/}
                                            {/*                    </div>*/}
                                            {/*                    <div key={i} className="mt-1 p-3">*/}
                                            {/*                        <p dangerouslySetInnerHTML={{ __html: noteText }}></p>*/}
                                            {/*                    </div>*/}
                                            {/*                </>*/}
                                            {/*            )*/}
                                            {/*        }*/}
                                            {/*    })*/}
                                            {/*}*/}

                                            <CartTermsConditions
                                                handleTermsAndConditions={handleTermsAndConditions}
                                                callIsCheckedAllTermsAndConditions={callIsCheckedAllTermsAndConditions}
                                            />

                                            {/* --- DEBUG --- */}
                                            {debug === "1" && (<><br /><br />{JSON.stringify(termsConditionsData)}<br />Terms and Conditions: {checkTermsAndConditions() ? "OK" : "KO"}</>)}
                                            {/* --- DEBUG --- */}
                                        </div>
                                    )}

                                    {/*Payments*/}
                                    {userPermissions.compositionAllowed && !userLockBook && haveAllProductPaymentMap && productBookParams && forceRefreshCartPayment && (
                                        <div className="basket-card card mt-5 mb-5 p-3">
                                            {
                                                callTermsFinished && <CartPayment
                                                    addictionalProducts={addictionalProducts}
                                                    selectedAddProdIds={selectedAddProdIds}
                                                    handleSelectedPaymentType={handleSelectedPaymentType}
                                                    handleHaveAllProductPaymentMap={handleHaveAllProductPaymentMap}
                                                    addedMkpFee={addedMkpFee}
                                                    modifiedMkpFee={modifiedMkpFee}
                                                    selectedBuoniScontoIds={selectedBuoniScontoIds}
                                                    welfareInfoCredit={cartInitData && cartInitData.welfareInfoCredit ? cartInitData.welfareInfoCredit : null}
                                                    onUpdateTotalPriceToPay={onUpdateTotalPriceToPay}
                                                />
                                            }

                                            {/* --- DEBUG --- */}
                                            {debug === "1" && (<><br /><br />Selected PaymentType: {selectedPaymentType}</>)}
                                            {/* --- DEBUG --- */}
                                        </div>
                                    )}

                                    {/*BookNow Button*/}
                                    {callTermsFinished && userPermissions.compositionAllowed && !userLockBook && selectedPaymentType && termsConditionsMandatoryChecked && canBookAnonymous() && (
                                        <div className="basket-card card mt-5 mb-5 p-3">
                                            <div>
                                                <M3Icon iconName="CreditCard" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                                <span className="basket titlecard" dangerouslySetInnerHTML={{ __html: t(`Button:Book`) }}></span>
                                            </div>
                                            <div className="border border-gray-500 rounded my-1">
                                                <div className="p-2">
                                                    <div className="titlecard">
                                                        <div className="d-inline-block pt-2">
                                                            <span>{!userPermissions || !userPermissions.cartOnlyOption ? t(`MyBook:ClickOnConfirmCart`) : t(`MyBook:ClickOnOptionCart`)}</span>
                                                        </div>
                                                        <div className="d-inline-block float-end">
                                                            <div>
                                                                <a id="btnBookNow" href="#" className="text-left my-1 w-100 btn btn-sm tp-btn-select py-2 " onClick={e => startBooking(e)}>
                                                                    {!userPermissions || !userPermissions.cartOnlyOption ? <span dangerouslySetInnerHTML={{ __html: t(`Button:Book`) }}></span> : t(`MyBook:Option`)}
                                                                </a>
                                                                <img id="imgBookLoading" src="https://cdn.travelplace.ch/common/images/general/ajax-loader.gif" alt="Loading..." style={{ display: 'none' }} />
                                                            </div>
                                                        </div>

                                                        {selectedPaymentType === 2 &&
                                                            <div className="pt-4">
                                                                <p className="text-success h6">
                                                                    {t(`MyBook:ClickOnConfirmCart2`)}
                                                                </p>
                                                            </div>
                                                        }

                                                        <div id="alertMandatoryFields" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_MandatoryFields`)}</p>
                                                        </div>
                                                        <div id="alertStartBookingError" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_StartBookingGenericError`)}</p>
                                                        </div>
                                                        <div id="alertBookParametersError" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_BookParametersError`)}</p>
                                                        </div>
                                                        <div id="alertWelfareCreditCheck" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_WelfareCreditNotAvailable`)}</p>
                                                        </div>
                                                        <div id="alertBookingTermsNotFinished" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_BookingTermsNotFinished`)}</p>
                                                        </div>
                                                        <div id="alertTermsConditions" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_TermsConditions`)}</p>
                                                        </div>
                                                        <div id="alertSamePaxes" className="pt-4" style={{ display: 'none' }}>
                                                            <p className="text-danger h6">{t(`Cart:Alert_SamePaxes`)}</p>
                                                        </div>

                                                        {invalidBookParameters && invalidBookParameters.length > 0 && <CartBookParameterError invalidBookParameters={invalidBookParameters} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/*bottom messages*/}
                                    {userLockBook && cartSaveToBooking && !userPermissions.messaggeError
                                        ? (<><h6 className="text-danger text-center mt-4">{t(`Cart:SaveToBooking_Msg`)} <a className="lnkReqInterventoBooking" onClick={e => openModalRichiestaInterventoBooking()}>{t(`Cart:SaveToBooking_MsgLink`)}</a>.</h6></>)
                                        : (<></>)
                                    }

                                    {userPermissions && userPermissions.messaggeError
                                        ? (<><h6 className="text-danger text-center mt-4" dangerouslySetInnerHTML={{ __html: t(`Cart:${userPermissions.messaggeError}`) }}></h6></>)
                                        : (<></>)
                                    }

                                    {welfareCreditNotAvailable
                                        ? (<><h6 className="text-danger text-center mt-4">{t(`Cart:Alert_WelfareCreditNotAvailable`)}</h6></>)
                                        : (<></>)
                                    }
                                </div>

                                {/*Cart Summary*/}
                                <CartSummary
                                    enableShowNetPrices={enableShowNetPrices}
                                    showCost={showCost}
                                    originalAddedMkpFee={addedMkpFee}
                                    selectedAddProdIds={selectedAddProdIds}
                                    addictionalProducts={addictionalProducts}
                                    callTermsFinished={callTermsFinished}
                                    handleAddedMkpFee={handleAddedMkpFee}
                                    handleModifyMkpFee={handleModifyMkpFee}
                                    userPermissions={userPermissions}
                                    selectedBuoniScontoIds={selectedBuoniScontoIds}
                                    productChangePolicyPrice={productChangePolicyPrice}
                                />
                            </div>
                        </div>
                    )
            }

            <ChangePolicyPrice productChangePolicyPrice={productChangePolicyPrice} />
            <ConfirmDeleteCartItemModal cartItemId={deleteCartItemId} />
        </>
    )
}
