import { useEffect, useState } from 'react';

export const PrintConfigButtons = ({ printParam, onClickPrintConfigButtons }) => {
    const [items, setItems] = useState({});

    const [checkboxes, setCheckboxes] = useState({
        B2BB2C: false,
        ShowCover: true,
        ShowPaxes: true,
        ShowProducts: true,
        ShowPricing: true,
        ShowGroupingPricing: true,
        ShowDynamicNote: true,
        ShowStaticScheda: true,

        ShowScadenziario: false,
        ShowCancelPolicy: false,
        ShowProductAlternative: false,
    });

    useEffect(() => {
        setItems(printParam);
        if (printParam) {
            let updCBs = { ...checkboxes };
            Object.keys(updCBs).forEach((item) => {
                if (item === 'B2BB2C') {
                    updCBs[item] = printParam[item] === 2;
                } else {
                    try {

                        if (Object.hasOwn(printParam, item)) {
                            updCBs[item] = printParam[item];
                        }
                        else {
                            delete updCBs[item]
                        }
                    } catch (ex) {
                        delete updCBs[item]
                    }
                }

            });

            setCheckboxes(updCBs);
        }

    }, [printParam]);

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [id]: checked
        }));

        const updItems = { ...items };
        if (id === 'B2BB2C') {
            updItems[id] = checked ? 2 : 1;
        } else {
            updItems[id] = checked;
        }

        try {
            if (id === 'ShowGroupingPricing' && checked) {
                setCheckboxes((prevState) => ({
                    ...prevState,
                    ShowPricing: false
                }));

                updItems['ShowPricing'] = false;
            } else if (id === 'ShowPricing' && checked) {
                setCheckboxes((prevState) => ({
                    ...prevState,
                    ShowGroupingPricing: false
                }));

                updItems['ShowGroupingPricing'] = false;

            }

        } catch (ex) {

        }
     
        onClickPrintConfigButtons(updItems);
    };

    function getLabel(key) {
        switch (key) {
            case 'B2BB2C': return 'Stampa Cliente';
            case 'ShowCover': return 'Mostra Cover';
            case 'ShowPaxes': return 'Mostra Lista Passeggeri';
            case 'ShowPricing': return 'Mostra Tabella Prezzi';
            case 'ShowGroupingPricing': return 'Mostra Tabella Prezzi raggruppata';
            case 'ShowProducts': return 'Mostra Dettaglio Prodotti';
            case 'ShowDynamicNote': return 'Mostra Note Dinamiche Pagina';
            case 'ShowStaticScheda': return 'Mostra Schede Statiche';
            case 'ShowScadenziario': return 'Mostra Scadenziario';
            case 'ShowCancelPolicy': return 'Mostra Dettaglio Penali Prodotto';
            case 'ShowProductAlternative': return 'Mostra Prodotti in alternativa';
            default: return key;
        }
    }


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h5 className="mb-4">Configura la tua stampa</h5>
                </div>

                {Object.keys(checkboxes).map((key) => (
                    <div className="col-6 col-sm-6 col-md-3" key={key}>
                        <div>
                            <input
                                type="checkbox"
                                className="form-check-input ml-0 mb-2"
                                id={key}
                                checked={checkboxes[key]}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                className="form-check-label"
                                style={{ marginLeft: '20px' }}
                                htmlFor={key}>
                                {getLabel(key)}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
